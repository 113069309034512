import React from 'react';
import {TableHeader} from '../../../components/table/Headers';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {AddButton} from '../../../components/button/AddButton';
import {useInvoke} from '../../../controllers/useInvoke';
import { Form } from '../../../components/form/Form';
import { useFormSubmit } from '../../../controllers/useSubmitForm';


const deletePrimaryReportee = ({invoke}) => ({
  title: 'Delete',
  confirm: {
    title: 'Delete Project Reportee',
    message: 'Do you want delete project reportee for this data?',
  },
  onPress: props => {
    const {row = {}} = props;
    invoke({
      uri: `/employeereportees/${row?._id}`,
    });
  },
});

export const EmployeeProjectReporteeTable = props => {
  const {
    route: {params},
  } = props;
  const {row} = params;
  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'employeereportees',
  });
  return (
    <Table
      eventSourceId={['employeereportees']}
      api={`/employeereportees`}
      fields={{
        project_reportee: { name: 1, color: 1, official_email_id: 1 },
        from_date: 1,
        to_date: 1,
      }}
      renderHeader={() => {
        return (
          <TableHeader
            title="Project Reportee"
            secondaryTitle={`${row?.name}`}
            actions={[
              <AddButton
                title="Project Reportee"
                view="add-project-reportee"
                params={params}
              />,
            ]}
          />
        );
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.project_reportee?.name}
                color={row?.project_reportee?.color}
                official_email_id={row?.project_reportee?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'Start Date',
          field: 'from_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
        {
          header: 'End Date',
          field: 'to_date',
          type: 'date',
          formatOptions: {format: 'DD MMM YY'},
        },
      ]}
      moreActions={[deletePrimaryReportee({invoke})]}
    />
  );
};


export const ProjectReporteeForm = props => {
  const {route: {params} = {}, headerTitle} = props;
  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeereportees',
    eventSourceId: 'employeereportees',
  });
  return (
    <Form
    header={{
      title: headerTitle,
      secondaryTitle: `${row?.name}`,
    }}
    defaultValues={{
      employee: row?._id,
    }}
    api={`/employeereportees/${row?._id}`}
    onSubmit={onSubmit}
    submitAction="Save"
      layoutFields={[
        {
          placeholder: 'Project Reportee',
          label: 'Project Reportee',
          field: 'project_reportee',
          type: 'autoComplete',
          api: `/employeeSuggestions`,
          suggestionField: 'name',
          valueField: 'name',
          secondarySuggestionField: 'official_email_id',
          required: true,
        },
        {
          label: 'Start Date',
          type: 'date',
          field: 'from_date',
          required: true,
          size: 6,
        },
        {
          label: 'End Date',
          type: 'date',
          field: 'to_date',
          size: 6,
        },
      ]}
      {...props}
    />
  );
};

export const AddProjectReporteeForm = props => {
  return <ProjectReporteeForm headerTitle="Project Reportee" {...props} />;
};

