import React from 'react';
import {Table} from '../../../components/table/Table';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {Tag} from '../../../components/chip/Chip';
import {Text, Row} from '@unthinkable/react-core-components';
import {MultiColorChip} from '../../../components/chip/MultiColorChip';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import moment from 'moment';
import {UserAvatar} from '../../../components/avatar/UserAvatar';

export const BilledList = props => {
  let {
    filterParams,
    period,
    route: {params},
    tab = '',
    searchValue,
    navigation,
    extraParams,
  } = props;
  const colors = useTheme('colors');
  params = {...params, period, ...extraParams};
  return (
    <Table
      params={params}
      api={'/employees/internalresources'}
      eventSourceId={'allocatedResource'}
      addOnFilter={{...filterParams}}
      search={searchValue}
      searchFields="name"
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Project',
          render: ({row}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE, BODY2} = useTheme('fonts');
            const projectLength = (row?.project && row?.project.length) || 0;
            const projectSubCategory =
              row?.project &&
              row?.project[0] &&
              row?.project[0].project_sub_category;
            const projectName =
              row?.project && row?.project[0] && row?.project[0].project;
            if (projectLength > 1) {
              return (
                <>
                  <Row gap={8} style={{alignItems: 'center'}}>
                    <Tag
                      value={projectName}
                      color={
                        row?.project && row?.project[0] && row?.project[0].color
                      }
                    />
                    <Text style={{...BODY2}}>+{projectLength - 1}</Text>
                  </Row>

                  <Text
                    style={{
                      ...CAPTION_LARGE,
                      color: colors.NEUTRAL_MEDIUM,
                      paddingLeft: 10,
                    }}>
                    {projectSubCategory}
                  </Text>
                </>
              );
            } else {
              return (
                <>
                  <Tag
                    value={projectName}
                    color={
                      row?.project && row?.project[0] && row?.project[0].color
                    }
                  />
                  <Text
                    style={{
                      ...CAPTION_LARGE,
                      color: colors.NEUTRAL_MEDIUM,
                      paddingLeft: 10,
                    }}>
                    {projectSubCategory}
                  </Text>
                </>
              );
            }
          },
        },
        {
          header: `Employee`,
          field: 'assigned_to',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
                primarySubTitle={row?.type}
                primarySubTitleColor={row?.types_color}
              />
            );
          },
          width: 240,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text
                style={{
                  ...rowText,
                  color: colors.NEUTRAL_MEDIUM,
                }}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 80,
        },
        // {
        //   header: 'Skill Mentor',
        //   width: 160,
        //   field: 'assigned_to.skill_leader',
        //   type: 'userAvatarChip',
        // },
        {
          header: 'Skill',
          field: 'resource_language.name',
          colorField: 'resource_language.color',
          type: 'colorTag',
        },
        {
          header: 'Allocation',
          field: 'totalActualWorkingHours',
          type: 'number',
          align: 'left',
          render: ({row, styles: {rowText}}) => {
            const colors = useTheme('colors');
            const {CAPTION_LARGE} = useTheme('fonts');
            return (
              <>
                <Text
                  style={{
                    ...rowText,
                    color:
                      row?.totalActualWorkingHours < 9 ? '#007AFF' : '#34C759',
                  }}>
                  {`${row?.totalActualWorkingHours.toFixed(0)} h`}
                </Text>
                <Text
                  style={{
                    ...CAPTION_LARGE,
                    color: colors.NEUTRAL_LOW,
                  }}>
                  Till :
                  {row?.assigned_to?.allocated_till &&
                    moment(row?.assigned_to?.allocated_till).format(
                      'DD MMM YY',
                    )}
                </Text>
              </>
            );
          },
        },
        {
          header: 'PM',
          width: 60,
          render: ({row}) => {
            return (
              <UserAvatar
                value={{
                  name: row?.project?.[0]?.owner_id?.name,
                  color: row?.project?.[0]?.owner_id?.color,
                }}
              />
            );
          },
        },
        {
          header: 'DO',
          width: 60,
          render: ({row}) => {
            return (
              <UserAvatar
                value={{
                  name: row?.level_two_owner?.name,
                  color: row?.level_two_owner?.color,
                }}
              />
            );
          },
        },
        // {
        //   header: 'Since',
        //   field: 'totalActualWorkingHours',
        //   type: 'number',
        //   align: 'left',
        //   render: ({row, styles: {rowText}}) => {
        //     const colors = useTheme('colors');
        //     const {CAPTION_LARGE} = useTheme('fonts');
        //     return (
        //       <>
        //         <Text
        //           style={{
        //             ...rowText,
        //             color:
        //               row?.totalActualWorkingHours < 9 ? '#007AFF' : '#34C759',
        //           }}>
        //           {`${row?.totalActualWorkingHours.toFixed(0)} h`}
        //         </Text>
        //         <Text
        //           style={{
        //             ...CAPTION_LARGE,
        //             color: colors.NEUTRAL_LOW,
        //           }}>
        //           Till :
        //           {row?.assigned_to?.allocated_from &&
        //             moment(row?.assigned_to?.allocated_from).format(
        //               'DD MMM YY',
        //             )}
        //         </Text>
        //       </>
        //     );
        //   },
        // },
        {
          header: 'Last Rating',
          render: ({row}) => {
            const colors = useTheme('colors');
            if (row?.assigned_to?.project_rating) {
              const {project_rating} = row?.assigned_to;
              if (project_rating == 'A+') {
                return (
                  <Chip
                    color={colors.SUCCESS_LOW}
                    textColor={colors.SUCCESS_HIGH}
                    value={'CE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'A') {
                return (
                  <Chip
                    color={colors.ACCENT6_LOW}
                    textColor={colors.ACCENT6_HIGH}
                    value={'OE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'B+') {
                return (
                  <Chip
                    color={colors.ACCENT1_LOW}
                    textColor={colors.ACCENT1_HIGH}
                    value={'ME'}
                    borderRadius={6}
                  />
                );
              }

              if (project_rating == 'B') {
                return (
                  <Chip
                    color={colors.WARNING_LOW}
                    textColor={colors.WARNING_HIGH}
                    value={'PE'}
                    borderRadius={6}
                  />
                );
              }
              if (project_rating == 'C') {
                return (
                  <Chip
                    color={colors.ERROR_LOW}
                    textColor={colors.ERROR_HIGH}
                    value={'DE'}
                    borderRadius={6}
                  />
                );
              }
            }
          },
          width: 100,
        },
      ]}
      moreActions={() => [
        {
          title: 'Mark Anchor Resource',
          onPress: ({row}) => {
            navigation.navigate(`replacement-form`, {
              ...row,
              defaultValues: {
                status: 'due',
                type: 'Anchor',
              },
            });
          },
        },
        {
          title: 'Mark BandWidth Release',
          onPress: ({row}) => {
            navigation.navigate(`replacement-form`, {
              ...row,
              defaultValues: {
                status: 'due',
                type: 'Bandwidth Release',
              },
            });
          },
        },
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
      ]}
    />
  );
};
