import React from 'react';
import {Form} from '../../../../components/form/Form';
import {useAppStateContext} from '../../../../providers/AppState';
import {HEADER_CONTENT_TYPES} from '../ApiConfigurationForm';
import NestedTable from '../../../../components/form/NestedTable';

export const SwaggerConfigForm = props => {
  const {
    route: {params},
  } = props;
  const {
    selectedIds,
    project_id,
    module_id,
    fromModule,
    fromProject,
    fromReleased,
  } = params;

  const defaultValues = {
    module_id,
    project_id,
    selectedIds,
    file_type: 'JSON',
    action_type: 'Download',
    responses: [
      {
        status_code: 200,
        resp_type: 'application/json',
        status_desc: ' Ok',
      },
    ],
    fromReleased,
  };

  const {download, fetch} = useAppStateContext();
  return (
    <Form
      header={{title: 'Swagger Configurations'}}
      onSubmit={data => {
        let uri = `/downloadSwagger`;

        if (fromModule) {
          uri = `/modules/${module_id}/downloadSwagger`;
        } else if (fromProject) {
          uri = `/projects/${project_id}/downloadSwagger`;
        }

        if (data.action_type === 'Commit') {
          fetch({
            uri,
            props: {
              params: data,
            },
          });
        } else {
          download({
            uri,
            props: data,
          });
        }
      }}
      submitAction="Save"
      defaultValues={defaultValues}
      successMessage={'Swagger file generated.'}
      layoutFields={[
        {
          label: 'File Type',
          field: 'file_type',
          type: 'radioGroup',
          options: ['JSON', 'YML'],
        },
        {
          label: 'Domain',
          type: 'text',
          field: 'domain',
          size: 6,
        },
        {
          label: 'Default Security type',
          type: 'autoComplete',
          field: 'security_type',
          options: ['BasicAuth', 'BearerAuth', 'ApiKeyAuth'],
          size: 6,
        },
        {
          label: 'Default Response type',
          field: 'responses',
          nested: true,
          render: props => (
            <NestedTable
              {...props}
              modalProps={{
                size: 'small',
              }}
              fields={[
                {
                  label: 'Code',
                  header: 'Code',
                  type: 'text',
                  field: 'status_code',
                  required: true,
                  onChangeValue: (value, _, {setFieldValue}) => {
                    setFieldValue('resp_type');
                  },
                },
                {
                  label: 'Description',
                  field: 'status_desc',
                  type: 'text',
                },
                {
                  visible: ({values}) => {
                    const {status_code} = values;
                    return status_code?.length;
                  },
                  label: 'Content Type',
                  field: 'resp_type',
                  type: 'autoComplete',
                  options: ({values, _parentValues: {responses}}) => {
                    const headerNotToShow = responses?.reduce(
                      (acc, {status_code, resp_type}) => {
                        if (status_code === values?.status_code) {
                          acc[resp_type] = 1;
                        }
                        return acc;
                      },
                      {},
                    );

                    return Object.keys(HEADER_CONTENT_TYPES)?.filter(
                      key => !headerNotToShow?.[key],
                    );
                  },
                  onChangeValue: (value, _, {setFieldValue}) => {
                    setFieldValue('response');
                  },
                },
              
              ]}
              columns={[
                {
                  header: 'Code',
                  type: 'text',
                  field: 'status_code',
                  width: 60,
                },
                {
                  header: 'Description',
                  field: 'status_desc',
                  type: 'text',
                  width: 80,
                },
                {
                  header: 'Detail',
                  type: 'text',
                  field: 'resp_type',
                },
              ]}
            />
          ),
        },
        {
          label: 'Actions',
          field: 'action_type',
          type: 'radioGroup',
          options: ['Download', 'Commit'],
        },
        {
          visible: ({values}) => values.action_type === 'Commit',
          fields: [
            {
              label: 'Select Repository',
              field: 'repository',
              type: 'autoComplete',
              api: `/projectRepositories`,
              filter: {project_id, type: 'Backend'},
              suggestionField: 'repository',
              secondarySuggestionField: 'repository',
              valueField: 'repository',
              required: true,
            },
            {
              label: 'Path',
              field: 'path',
              type: 'text',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
