import React from 'react';
import {Form} from '../../../../components/form/Form';
import {FormErrorConfirmWrapper} from '../../../../components/form/FormWrapper';
import {useFormSubmit} from '../../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../../providers/AppState';

const updateAllocationComputations = fetch => ({
  'get is_transferred and transferred from': {
    compute: async value => {
      if (value?.employee?._id) {
        const {data: employeeData} = await fetch({
          uri: '/getEmployeeDeliveryOwner',
          props: {
            filter: {
              _id: value?.employee?._id,
            },
            fields: {delivery_owner: {_id: 1, name: 1}},
            only: true,
          },
        });
        if (
          employeeData?.delivery_owner?._id.toString() !==
          value?.level_two_owner?._id.toString()
        ) {
          return {
            is_transfered: true,
            transfered_from: employeeData?.delivery_owner,
          };
        }
      }
    },
    multi: true,
    dependencies: ['employee', 'hours'],
  },
});

const UpdateAllocation = props => {
  const {
    route: {params},
  } = props;
  let {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/employeeassignments',
    eventSourceId: 'allocatedResource',
    ...props,
  });
  const {fetch} = useAppStateContext();
  return (
    <FormErrorConfirmWrapper
      onError={err => {
        if (
          err?.message === 'Total allocation can not be more than 100%' ||
          new RegExp(
            '\\b' +
              "A Resource Can't Be allocated for more then 9 hrs in a single day" +
              '\\b',
            'i',
          ).test(err?.message)
        ) {
          return {
            confirm: {
              title: 'Error',
              message: 'Are you sure you want to allocate more than 9 hours!!',
            },
            updates: {
              allow_allocation_more_than_100: true,
            },
          };
        }
      }}>
      <Form
        computations={{...updateAllocationComputations(fetch)}}
        header="Update Allocation"
        api={`/employeeassignments/${row._id}`}
        fields={{
          // hours: 1,
          percentage: 1,
          is_applicable_for_ma: 1,
          employee: {_id: 1, name: 1, color: 1},
          from_date: 1,
          to_date: 1,
          object: 1,
          product: 1,
          project_id: {_id: 1, project: 1, new_project_type: 1},
          department: {_id: 1, name: 1},
          skill_metrics: {_id: 1, name: 1},
          project_skill: {_id: 1, name: 1},
          resource_skill: {_id: 1, name: 1},
          front_facing_resource: {_id: 1, name: 1},
          tool: {_id: 1, name: 1},
          resource_tool: {_id: 1, name: 1},
          level_one_owner: {_id: 1, name: 1},
          level_two_owner: {_id: 1, name: 1},
          level_three_owner: {_id: 1, name: 1},
          order: {_id: 1, order_number: 1},
          applicable_for_daily_incentive: 1,
          allow_allocation_more_than_100: 1,
          is_included_in_utilisation: 1,
          percIncludeInUtilisation: 1,
          is_transfered: 1,
          transfered_from: {_id: 1, name: 1},
          anchor_resource: {_id: 1, name: 1, color: 1},
          tech_lead: {_id: 1, name: 1, color: 1},
          daily_incentive: 1,
          delivery_owner: {_id: 1, name: 1, color: 1},
          shift_timing: 1,
          exclude_project_efficiency: 1,
          incentive_mode: 1,
          incentive_shared_with: {name: 1, color: 1},
        }}
        mode="edit"
        onSubmit={onSubmit}
        submitAction={'Save'}
        layoutFields={[
          {
            label: 'Employee',
            placeholder: 'Employee',
            field: 'employee',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
          },
          {
            label: 'Front Facing Resource',
            placeholder: 'Front Facing Resource',
            field: 'front_facing_resource',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
          },
          {
            label: 'Start Date',
            placeholder: 'Start Date',
            field: 'from_date',
            type: 'date',
            size: 4,
            readOnly: true,
          },
          {
            label: 'End Date',
            placeholder: 'End Date',
            field: 'to_date',
            type: 'date',
            size: 4,
            readOnly: true,
          },
          {
            label: 'Hours',
            placeholder: 'Hours',
            field: 'hours',
            type: 'number',
            size: 4,
          },
          {
            label: 'Project',
            placeholder: 'Project',
            field: 'project_id',
            type: 'autoComplete',
            api: `/projects`,
            suggestionField: 'project',
            valueField: 'project',
            size: 4,
            readOnly: true,
          },
          {
            label: 'Project Skill',
            placeholder: 'Project Skill',
            field: 'tool',
            type: 'autoComplete',
            api: `/tools`,
            suggestionField: 'name',
            valueField: 'name',
            size: 4,
          },
          {
            label: 'Resource Skill',
            placeholder: 'Resource Skill',
            field: 'resource_tool',
            type: 'autoComplete',
            api: `/tools`,
            suggestionField: 'name',
            valueField: 'name',
            size: 4,
          },
          {
            label: 'Anchor Resource',
            placeholder: 'Anchor Resource',
            field: 'anchor_resource',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
          },
          {
            label: 'Tech Lead',
            placeholder: 'Tech Lead',
            field: 'tech_lead',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
          },
          {
            label: 'Delivery Owner',
            placeholder: 'Delivery Owner',
            field: 'delivery_owner',
            type: 'autoComplete',
            api: `/employeeSuggestions`,
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            size: 6,
          },
          {
            type: 'switch',
            label: 'Shift allowance',
            field: 'applicable_for_daily_incentive',
            size: 3,
          },
          {
            type: 'number',
            label: 'Amount',
            field: 'daily_incentive',
            visible: ({values = {}}) => values?.applicable_for_daily_incentive,
            size: 4,
          },
          {
            type: 'text',
            label: 'Shift Timing',
            field: 'shift_timing',
            visible: ({values = {}}) => values?.applicable_for_daily_incentive,
            size: 4,
          },
          {
            type: 'switch',
            field: 'is_wrong_input',
            placeholder: 'Worng Input',
            label: 'Worng Input',
            size: 3,
          },
          {
            type: 'switch',
            label: 'Is Included In Utilisation',
            field: 'is_included_in_utilisation',
            size: 3,
          },
          {
            label: 'Percentage Included In Utilisation',
            field: 'percIncludeInUtilisation',
            type: 'autoComplete',
            options: [
              {label: '100 %', value: 100},
              {label: '75 %', value: 75},
              {label: '50 %', value: 50},
            ],
            visible: ({values = {}}) => values?.is_included_in_utilisation,
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
            required: true,
            size: 4,
          },
          {
            type: 'switch',
            label: 'Is Transferred',
            field: 'is_transfered',
            size: 3,
          },
          {
            type: 'switch',
            label: 'Is exclude project efficiency',
            field: 'exclude_project_efficiency',
            size: 12,
          },
          {
            size: 6,
            groups: [
              {
                defaultHidden: true,
                label: 'Leader',
                fields: [
                  {
                    label: 'Skill Leader',
                    placeholder: 'Skill Leader',
                    field: 'level_one_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    readOnly: true,
                    size: 4,
                  },
                  {
                    label: 'Project Owner',
                    placeholder: 'Project Owner',
                    field: 'level_two_owner',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    readOnly: true,
                    size: 4,
                  },
                ],
              },
              {
                defaultHidden: true,
                label: 'Incentive Mode',
                fields: [
                  {
                    label: 'Incentive Mode',
                    type: 'radioGroup',
                    field: 'incentive_mode',
                    options: ({values}) => {
                      let incentiveOptions = [];
                      if (
                        values?.project_id?.new_project_type ==
                          'staffaugmentation' ||
                        values?.project_id?.new_project_type == 'managedteam'
                      ) {
                        return (incentiveOptions = [
                          {
                            label: 'Reporting To',
                            value: 'Reporting To',
                          },
                          {
                            label: 'Sharing With PM',
                            value: 'Sharing With PM',
                          },
                          {
                            label: 'Pay To Skill Manager',
                            value: 'Pay To Skill Manager',
                          },
                          {label: 'No Mentorship', value: 'No Mentorship'},
                        ]);
                      }
                      if (
                        values?.project_id?.new_project_type == 'sdlc' ||
                        values?.project_id?.new_project_type == 'sdlcplus'
                      ) {
                        return (incentiveOptions = [
                          {
                            label: 'Pay To Module Lead',
                            value: 'Pay To Module Lead',
                          },
                          {
                            label: 'Sharing With Skill Manager',
                            value: 'Sharing With Skill Manager',
                          },
                        ]);
                      }
                    },
                    required: true,
                  },
                  {
                    label: 'Incentive Shared With',
                    placeholder: 'Incentive Shared With',
                    field: 'incentive_shared_with',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    visible: ({values = {}}) =>
                      values?.incentive_mode === 'Sharing With PM' ||
                      values?.incentive_mode === 'Pay To Skill Manager' ||
                      values?.incentive_mode === 'Sharing With Skill Manager',
                    required: true,
                  },
                ],
              },
            ],
          },
          {
            groups: [
              {
                defaultHidden: true,
                label: 'Transferred From',
                fields: [
                  {
                    label: 'Transferred From',
                    placeholder: 'Transferred From',
                    field: 'transfered_from',
                    type: 'autoComplete',
                    api: `/employeeSuggestions`,
                    suggestionField: 'name',
                    secondarySuggestionField: 'official_email_id',
                    valueField: 'name',
                    size: 4,
                  },
                ],
              },
            ],
          },
        ]}
        eventSourceId={['allocatedResource']}
        {...props}
      />
    </FormErrorConfirmWrapper>
  );
};

export default UpdateAllocation;
