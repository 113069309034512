import React from 'react';
import { Table } from '../../../components/table/Table';
import { TableHeader } from '../../../components/table/Headers';

export const ContactTable = () => {

  return (
    <Table
      api={`/contacts`}
      fields={{
        name: 1,
        email: 1,
        mobile: 1,
        requirement: 1,
      }}
     renderHeader={() => (
        <TableHeader
          title="Contacts"
        />
      )}
      columns={[
        {
          type: 'text',
          field: 'name',
          header: 'Name',
          width: 150,
        },
        {
          type: 'text',
          field: 'email',
          header: 'Email',
          align: 'center',
          width: 275,
        },
        {
          type: 'text',
          field: 'mobile',
          header: 'Phone',
          width: 150,
        },
        {
          type: 'textArea',
          field: 'requirement',
          header: 'Requirement',
        },
      ]}
    />
  );
};
