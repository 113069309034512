import React from 'react';
import {GroupTable, Table} from '../../../components/table/Table';
import {Tag} from '../../../components/chip/Chip';
import {useInvoke} from '../../../controllers/useInvoke';
import {Col, Text, View} from '@unthinkable/react-core-components';
import moment from 'moment';
import {UserAvatarWithNameAndEmail} from '../../reimbursement/components/ReimbursementColumnRenders';
import {MultiColorChip} from '../../../components/chip/MultiColorChip';
import {useTheme} from '@unthinkable/react-theme';
import {Chip} from '@unthinkable/react-chip';
import {UserAvatar} from '../../../components/avatar/UserAvatar';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {GroupContentItem} from '../../../components/card/GroupContentItem';

export const RenderSkills = props => {
  const {row} = props;
  const skillName = row?.assigned_to?.employee_skills
    ?.map(element => {
      return element?.name;
    })
    .join(', ');
  if (!skillName) return null;
  return <Tag value={skillName} />;
};

export const renderStatusForAll = ({row = {}, styles = {}}) => {
  let {
    status = '',
    employeeLastAssignProject = {},
    project = [],
    assigned_to = {},
  } = row || {};
  if (!status) return null;

  if (status === 'Fully Available') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag
          value={assigned_to?.types ? assigned_to?.types : status}
          color={'#E0EFFF'}
        />
        {employeeLastAssignProject?.project_category && (
          <Text style={{...styles.rowText, marginLeft: 6, color: '#737373'}}>
            {employeeLastAssignProject?.project_category}
          </Text>
        )}
      </View>
    );
  }

  if (status === 'Not Available') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag
          value={assigned_to?.types ? assigned_to?.types : status}
          color={'#E0EFFF'}
        />
        {employeeLastAssignProject?.project_category && (
          <Text style={{...styles.rowText, marginLeft: 6, color: '#737373'}}>
            {employeeLastAssignProject?.project_category}
          </Text>
        )}
      </View>
    );
  }

  if (status === 'Partialy Available') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag
          value={assigned_to?.types ? assigned_to?.types : status}
          color={'#DBFFFE'}
        />
      </View>
    );
  }
};

export const renderStatusForReserved = ({row = {}}) => {
  let {
    status = '',
    employeeLastAssignHours,
    employeeLastAssignProject = {},
    project = [],
    totalActualWorkingHours,
  } = row || {};
  if (!status) return null;

  if (status === 'Fully Available') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag value={status} color={'#E0EFFF'} />
        <View style={{marginLeft: 5}}>
          <PrimaryColumnCard
            items={[
              {value: employeeLastAssignProject?.project, width: 80},
              {value: employeeLastAssignHours && `${employeeLastAssignHours}h`},
            ]}
          />
        </View>
      </View>
    );
  }

  if (status === 'Partialy Available') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag value={status} color={'#DBFFFE'} />
        <View style={{marginLeft: 5}}>
          <PrimaryColumnCard
            items={[
              {value: employeeLastAssignProject?.project, width: 80},
              {value: employeeLastAssignHours && `${employeeLastAssignHours}h`},
            ]}
          />
        </View>
      </View>
    );
  }
  if (status === 'Fixed Shadow') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag value={status} color={'#F5E0FF'} />
        <View style={{marginLeft: 5}}>
          <PrimaryColumnCard
            items={[
              {
                value: project?.length
                  ? project?.[0]?.project
                  : employeeLastAssignProject?.project,
                width: 80,
              },
              {
                value: project?.length
                  ? totalActualWorkingHours && `${totalActualWorkingHours}h`
                  : employeeLastAssignHours && `${employeeLastAssignHours}h`,
              },
            ]}
          />
        </View>
      </View>
    );
  }
  if (status === 'KT For Swapping') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag value={status} color={'#FFEFE0'} />
        <View style={{marginLeft: 5}}>
          <PrimaryColumnCard
            items={[
              {
                value: project?.length
                  ? project?.[0]?.project
                  : employeeLastAssignProject?.project,
                width: 80,
              },
              {
                value: project?.length
                  ? totalActualWorkingHours && `${totalActualWorkingHours}h`
                  : employeeLastAssignHours && `${employeeLastAssignHours}h`,
              },
            ]}
          />
        </View>
      </View>
    );
  }
  if (status === 'KT For Resignation') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag value={status} color={'#DBFFFE'} />
        <View style={{marginLeft: 5}}>
          <PrimaryColumnCard
            items={[
              {
                value: project?.length
                  ? project?.[0]?.project
                  : employeeLastAssignProject?.project,
                width: 80,
              },
              {
                value: project?.length
                  ? totalActualWorkingHours && `${totalActualWorkingHours}h`
                  : employeeLastAssignHours && `${employeeLastAssignHours}h`,
              },
            ]}
          />
        </View>
      </View>
    );
  }
  if (status === 'Project Shadow') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag value={status} color={'#E0EFFF'} />
        <View style={{marginLeft: 5}}>
          <PrimaryColumnCard
            items={[
              {
                value: project?.length
                  ? project?.[0]?.project
                  : employeeLastAssignProject?.project,
                width: 80,
              },
              {
                value: project?.length
                  ? totalActualWorkingHours && `${totalActualWorkingHours}h`
                  : employeeLastAssignHours && `${employeeLastAssignHours}h`,
              },
            ]}
          />
        </View>
      </View>
    );
  }

  if (status === 'Unbillable') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag value={status} color={'#E0EFFF'} />
        <View style={{marginLeft: 5}}>
          <PrimaryColumnCard
            items={[
              {
                value: project?.length
                  ? project?.[0]?.project
                  : employeeLastAssignProject?.project,
                width: 80,
              },
              {
                value: project?.length
                  ? totalActualWorkingHours && `${totalActualWorkingHours}h`
                  : employeeLastAssignHours && `${employeeLastAssignHours}h`,
              },
            ]}
          />
        </View>
      </View>
    );
  }

  if (status === 'Not Available') {
    return (
      <View style={{flexDirection: 'column', gap: 4}}>
        <Tag value={status} color={'#E0EFFF'} />
        <View style={{marginLeft: 5}}>
          <PrimaryColumnCard
            items={[
              {
                value: project?.length
                  ? project?.[0]?.project
                  : employeeLastAssignProject?.project,
                width: 80,
              },
              {
                value: project?.length
                  ? totalActualWorkingHours && `${totalActualWorkingHours}h`
                  : employeeLastAssignHours && `${employeeLastAssignHours}h`,
              },
            ]}
          />
        </View>
      </View>
    );
  }
};

export const updateCV = ({navigation, params}) => ({
  title: 'Update CV',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-cv', {
      row,
    });
  },
});

export const updateResourceSkill = ({navigation, params}) => ({
  title: 'Update Resource Language',
  onPress: props => {
    const {row} = props;
    navigation.navigate('update-resource-skill', {
      row,
    });
  },
});

export const assignAsReplacement = ({navigation, params}) => ({
  title: 'Assign As Replacement',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-as-replacement', {
      row,
    });
  },
});

export const unMarkTraining = ({invoke}) => ({
  title: 'Un-Mark Status',
  confirm: {
    title: 'Un-Mark Status',
    message: 'Do you want un-mark the status of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        proposed_deployment_date: null,
        proposed_deployment_comment: null,
        training_with_whom: null,
        bench_training_type: null,
        is_from_bench_resource: null,
        is_from_batch_trainee: null,
        working_status: null,
      },
    });
  },
});

export const proposedDeploymentDate = ({navigation, params}) => ({
  title: 'Plan For Deployment',
  onPress: props => {
    const {row} = props;
    navigation.navigate('proposed-deployment-date', {
      row,
    });
  },
});

export const assignTraining = ({navigation, params}) => ({
  title: 'Assign Training',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-training', {
      row,
    });
  },
});

export const markNotAvailable = ({navigation, params}) => ({
  title: 'Mark Unavailable',
  onPress: props => {
    const {row} = props;
    navigation.navigate('mark-not-available', {
      row,
    });
  },
});

export const moduleDevelopmentAssignment = ({navigation, params}) => ({
  title: 'Module Development Assignment',
  onPress: props => {
    const {row} = props;
    navigation.navigate('module-development-assignment-form', {
      row,
    });
  },
});

export const markUnBillable = ({invoke}) => ({
  title: 'Mark Unbillable',
  confirm: {
    title: 'Mark Unbillable',
    message: 'Do you want Mark Unbillable of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        working_status: 'Unbillable',
      },
    });
  },
});

export const assignAsLearning = ({navigation, params}) => ({
  title: 'Assign Shadow For Learning',
  onPress: props => {
    const {row} = props;
    navigation.navigate('assign-as-learning', {
      row,
    });
  },
});

const RenderStatus = ({row}) => {
  return (
    <View style={{flexDirection: 'column', gap: 4}}>
      <Tag
        value={
          row?.expected_relieving_date?.length
            ? 'Under Resignation'
            : row?.working_status
        }
      />
      <Text
        style={{
          fontSize: 12,
          fontFamily: 'Inter-Medium',
          lineHeight: '16px',
          color: '#A3A3A3',
        }}>
        {row?.expected_relieving_date?.length
          ? `Exit ${moment(row?.expected_relieving_date).format('DD MMM YY')}`
          : row?.working_date &&
            `Till ${moment(row?.working_date).format('DD MMM YY')}`}
      </Text>
      {row?.not_available_until_date && (
        <Text
          style={{
            fontSize: 12,
            fontFamily: 'Inter-Medium',
            lineHeight: '16px',
            color: '#A3A3A3',
          }}>
          {`Untill ${moment(row?.not_available_until_date).format(
            'DD MMM YY',
          )}`}
        </Text>
      )}
    </View>
  );
};

export const renderExperience = ({row = {}, styles: {rowText = {}}}) => {
  return (
    <Text style={{...rowText}}>
      {row?.experience && `${row?.experience} yr`}
    </Text>
  );
};

const EXPERIENCE = {
  header: 'Exp.',
  align: 'right',
  render: renderExperience,
  width: 80,
};

const SKILLS = {
  header: 'Skill',
  field: 'assigned_to.resource_language.name',
  type: 'colorTag',
  colorField: 'resource_language.color',
  width: 120,
};

export const renderAvailablitity = ({row = {}, styles = {}}) => {
  let {rowText = {}} = styles;
  let {totalActualWorkingHours = 0} = row || {};
  let freeHours = 0;
  freeHours = 9 - Number(totalActualWorkingHours);
  if (Number.isInteger(freeHours)) {
    return (
      <Text
        style={
          freeHours === 9
            ? {...rowText, color: '#34C759'}
            : {...rowText, color: '#007AFF'}
        }>
        {`${freeHours} h`}
      </Text>
    );
  } else {
    return (
      <Text
        style={
          freeHours === 9
            ? {...rowText, color: '#34C759'}
            : {...rowText, color: '#007AFF'}
        }>
        {`${freeHours.toFixed(2)} h`}
      </Text>
    );
  }
};

const lastRating = ({row = {}}) => {
  const colors = useTheme('colors');
  if (row?.assigned_to?.project_rating) {
    const {project_rating} = row?.assigned_to;
    if (project_rating == 'A+') {
      return (
        <Chip
          color={colors.SUCCESS_LOW}
          textColor={colors.SUCCESS_HIGH}
          value={'CE'}
          borderRadius={6}
        />
      );
    }
    if (project_rating == 'A') {
      return (
        <Chip
          color={colors.ACCENT6_LOW}
          textColor={colors.ACCENT6_HIGH}
          value={'OE'}
          borderRadius={6}
        />
      );
    }
    if (project_rating == 'B+') {
      return (
        <Chip
          color={colors.ACCENT1_LOW}
          textColor={colors.ACCENT1_HIGH}
          value={'ME'}
          borderRadius={6}
        />
      );
    }
    if (project_rating == 'B') {
      return (
        <Chip
          color={colors.WARNING_LOW}
          textColor={colors.WARNING_HIGH}
          value={'PE'}
          borderRadius={6}
        />
      );
    }
    if (project_rating == 'C') {
      return (
        <Chip
          color={colors.ERROR_LOW}
          textColor={colors.ERROR_HIGH}
          value={'DE'}
          borderRadius={6}
        />
      );
    }
  }
};

export const RenderBenchSince = props => {
  const {row, styles = {}} = props;
  const {rowText} = styles;
  const colors = useTheme('colors');
  const {CAPTION_LARGE} = useTheme('fonts');
  return (
    <Col>
      <Text
        style={{
          ...rowText,
        }}>
        {row?.bench_days || 0} days
      </Text>
      <Text
        style={{
          ...CAPTION_LARGE,
          color: colors.NEUTRAL_LOW,
        }}>
        {row.bench_from && moment(row.bench_from).format('DD MMM, YY')}
      </Text>
    </Col>
  );
};

export const UnavailableList = props => {
  let {
    navigation,
    route: {params},
  } = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['EmployeeAssignment', 'workload-billable']}
      limit={2000}
      searchFields="name"
      api={`/employees/unavailable`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Unavailable resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.name}
                color={row?.color}
                official_email_id={row?.official_email_id}
              />
            );
          },
          width: 240,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'Delivery Owner',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.delivery_owner',
        },
        {
          header: 'Skill Mentor',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.skill_leader',
        },
        {
          header: 'Bench Days',
          width: 120,
          render: RenderBenchSince,
        },
        {
          header: 'Status',
          render: RenderStatus,
          width: 150,
        },
        // {
        //   header: 'Till',
        //   type: 'date',
        //   field: 'assignedTo.allocated_till',
        //   formatOptions: {
        //     format: 'DD MMM YY',
        //   },
        //   align: 'right',
        // },
        {
          header: 'Last Rating',
          render: lastRating,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        // markNotAvailable({navigation, params}),
        assignAsLearning({navigation, params}),
        markUnBillable({invoke}),
        unMarkTraining({invoke}),
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
      ]}
    />
  );
};

export const ReservedList = props => {
  let {
    navigation,
    route: {params},
  } = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'allocatedResource',
  });
  return (
    <Table
      {...props}
      params={{...params}}
      eventSourceId={['allocatedResource']}
      searchFields="name"
      api={`/reservedResources`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Fixed resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.assigned_to?.name}
                color={row?.assigned_to?.color}
                official_email_id={row?.assigned_to?.official_email_id}
              />
            );
          },
          width: 240,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'Delivery Owner',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.delivery_owner',
        },
        {
          header: 'Skill Mentor',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.skill_leader',
        },
        {
          header: 'Bench Days',
          width: 120,
          render: RenderBenchSince,
        },
        {
          header: 'Status',
          render: renderStatusForReserved,
          width: 200,
        },
        {
          header: 'Till',
          type: 'date',
          field: 'assigned_to.allocated_till',
          formatOptions: {
            format: 'DD MMM YY',
          },
          align: 'right',
        },
        {
          header: 'Last Rating',
          render: lastRating,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        unMarkTraining({invoke}),
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
      ]}
    />
  );
};

export const UnderTraining = props => {
  let {
    navigation,
    route: {params},
    period,
    addOnParams,
  } = props;

  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      {...props}
      params={{...params, ...addOnParams, period}}
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      searchFields="name"
      api={`/employees/internalresources`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.assigned_to?.name}
                color={row?.assigned_to?.color}
                official_email_id={row?.assigned_to?.official_email_id}
              />
            );
          },
          width: 200,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'DO',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.delivery_owner',
        },
        // {
        //   header: 'TL',
        //   width: 180,
        //   type: 'userAvatarChip',
        //   field: 'assigned_to.skill_leader',
        // },
        // SKILLS,
        // {
        //   header: 'Skill Mentor',
        //   align: 'left',
        //   width: 140,
        //   render: ({row}) => {
        //     const {assigned_to} = row;
        //     return <UserAvatar value={assigned_to?.skill_leader} />;
        //   },
        // },
        {
          header: 'Training',
          field: 'project',
          width: 200,
          render: ({row}) => {
            const projectName = row?.project
              ?.map(item => item?.project)
              .join(',');
            return (
              <PrimaryColumnCard
                primaryTitle={projectName}
                items={[
                  {
                    value:
                      row?.assigned_to.allocated_till &&
                      `Till ${moment(row?.assigned_to.allocated_till).format(
                        'DD MMM YY',
                      )}`,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Bench Days',
          width: 120,
          render: RenderBenchSince,
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        unMarkTraining({invoke}),
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
      ]}
    />
  );
};

export const SocialAndInternalResource = props => {
  let {
    navigation,
    route: {params},
    tab,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  if (tab == 'Available') {
    params.fromAvailableTab = true;
  } else if (tab == 'Unbilled Deployments') {
    params.fromUnbilledDeploymentsTab = true;
  } else if (tab == 'Unbilled Deployments Internal') {
    params.forInternalTab = true;
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      searchFields="name"
      addOnFilter={{...rest}}
      api={`/employees/bench`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Project',
          field: 'employeeLastAssignProject.project',
          width: 150,
        },
        {
          header: 'Employee',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 200,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'Avail.',
          align: 'right',
          width: 60,
          field: 'employeeLastAssignHours',
        },
        {
          header: 'PM',
          width: 140,
          field: 'employeeLastAssignProject.owner_id',
          type: 'userAvatarChip',
        },
        {
          header: 'DO',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.delivery_owner',
        },
        // {
        //   header: 'Skill Mentor',
        //   width: 180,
        //   type: 'userAvatarChip',
        //   field: 'assigned_to.skill_leader',
        // },
        {
          header: 'Bench Days',
          width: 120,
          render: RenderBenchSince,
        },
        // {
        //   header: 'Status',
        //   render: renderStatusForAll,
        //   width: 200,
        // },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
      ]}
      {...props}
      params={params}
    />
  );
};

export const AvailableResource = props => {
  let {
    navigation,
    route: {params},
    tab,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  if (tab == 'Available') {
    params.fromAvailableTab = true;
  } else if (tab == 'Unbilled Deployments') {
    params.fromUnbilledDeploymentsTab = true;
  } else if (tab == 'Unbilled Deployments Internal') {
    params.forInternalTab = true;
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      searchFields="name"
      addOnFilter={{...rest}}
      api={`/employees/bench`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Available resource',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 240,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'DO',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.delivery_owner',
        },
        {
          header: 'TL',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.skill_leader',
        },
        {
          header: 'Avl. Hrs',
          align: 'right',
          render: renderAvailablitity,
          width: 70,
        },
        {
          header: 'Bench Days',
          width: 120,
          render: RenderBenchSince,
        },
        {
          header: 'Status',
          render: renderStatusForAll,
          width: 140,
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
      ]}
      {...props}
      params={params}
    />
  );
};

export const AllResourceOnBench = props => {
  let {
    navigation,
    route: {params},
    addOnParams,
    addOnFilter = {},
  } = props;
  const {period, ...rest} = params;
  params = {
    ...addOnParams,
    ...rest,
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      addOnFilter={{...addOnFilter}}
      searchFields="name"
      api={`/employees/bench`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Engineer',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          minWidth: 240,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'Skill Mentor',
          align: 'right',
          width: 100,
          render: ({row}) => {
            const {assigned_to} = row;
            return <UserAvatar value={assigned_to?.skill_leader} />;
          },
        },
        {
          header: 'Bench Days',
          render: RenderBenchSince,
          width: 120,
        },
        {
          header: 'Status',
          render: renderStatusForAll,
          width: 200,
        },
        {
          header: 'Availability',
          align: 'right',
          render: renderAvailablitity,
          width: 90,
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
      ]}
    />
  );
};

export const OtherResourceOnBench = props => {
  let {
    navigation,
    route: {params = {}} = {},
    addOnParams,
    extraAddOnFilter = {},
  } = props;
  const {period = {}, ...rest} = params || {};
  params = {
    ...addOnParams,
    ...rest,
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <GroupTable
      {...props}
      params={params}
      eventSourceId={['EmployeeAssignment', 'allocatedResource']}
      limit={2000}
      addOnFilter={{...extraAddOnFilter}}
      searchFields="name"
      api={`/depatmentTag/others`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.name} />
              <GroupContentItem value={`${row?.count}`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Engineer',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          minWidth: 240,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'Skill Mentor',
          align: 'right',
          width: 100,
          render: ({row}) => {
            const {assigned_to} = row;
            return <UserAvatar value={assigned_to?.skill_leader} />;
          },
        },
        {
          header: 'Bench Days',
          render: RenderBenchSince,
          width: 120,
        },
        {
          header: 'Status',
          render: renderStatusForAll,
          width: 200,
        },
        {
          header: 'Availability',
          align: 'right',
          render: renderAvailablitity,
          width: 90,
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
      ]}
    />
  );
};

export const DepartmentPlannedEmployee = props => {
  let {
    navigation,
    route: {params = {}} = {},
    addOnParams,
    extraAddOnFilter = {},
    search,
  } = props;
  const {period = {}, ...rest} = params || {};
  params = {
    ...addOnParams,
    ...rest,
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      search={search}
      addOnFilter={{...extraAddOnFilter}}
      searchFields="name"
      api={`/departmentPlannedEmployees`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Engineer',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          minWidth: 240,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'Skill Mentor',
          align: 'right',
          width: 100,
          render: ({row}) => {
            const {assigned_to} = row;
            return <UserAvatar value={assigned_to?.skill_leader} />;
          },
        },
        {
          header: 'Bench Days',
          render: RenderBenchSince,
          width: 120,
        },
        {
          header: 'Status',
          render: renderStatusForAll,
          width: 200,
        },
        {
          header: 'Dep. Date/ Expt. Date',
          render: ({row, styles: {rowText = {}} = {}}) => {
            const {assigned_to} = row;
            return (
              <Text style={{...rowText}}>
                {(assigned_to?.proposed_deployment_date &&
                  moment(assigned_to?.proposed_deployment_date).format(
                    'DD MMM YY',
                  )) ||
                  (assigned_to?.expected_relieving_date &&
                    moment(assigned_to?.expected_relieving_date).format(
                      'DD MMM YY',
                    ))}
              </Text>
            );
          },
        },
        {
          header: 'Availability',
          align: 'right',
          render: renderAvailablitity,
          width: 90,
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
      ]}
    />
  );
};

export const AllResourceOnBenchGroupByStatus = props => {
  let {
    navigation,
    route: {params},
    addOnParams,
    extraAddOnFilter = {},
  } = props;
  const {period, ...rest} = params;
  params = {
    ...addOnParams,
    ...rest,
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <GroupTable
      {...props}
      params={{...params, fromDeparmentUnBilled: true}}
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      addOnFilter={{...extraAddOnFilter}}
      searchFields="name"
      api={`/employees/bench`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.status} />
              <GroupContentItem value={`${row?.count?.toFixed(1)}`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Engineer',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          minWidth: 240,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'Skill Mentor',
          align: 'right',
          width: 100,
          render: ({row}) => {
            const {assigned_to} = row;
            return <UserAvatar value={assigned_to?.skill_leader} />;
          },
        },
        {
          header: 'Bench Days',
          render: RenderBenchSince,
          width: 120,
        },
        {
          header: 'Status',
          render: renderStatusForAll,
          width: 200,
        },
        {
          header: 'Availability',
          align: 'right',
          render: renderAvailablitity,
          width: 90,
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
      ]}
    />
  );
};

export const AllResourceOnBenchAnlaytics = props => {
  let {
    navigation,
    route: {params},
    addOnParams,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...addOnParams,
    ...rest,
  };
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      {...props}
      params={params}
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      searchFields="name"
      api={`/employees/bench`}
      filter={{
        _id: {$in: params?.row},
      }}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'Bench Days',
          render: RenderBenchSince,
          width: 120,
        },
        {
          header: 'Status',
          render: renderStatusForReserved,
          width: 200,
        },
        {
          header: 'Availability',
          align: 'right',
          render: renderAvailablitity,
          width: 90,
        },
        {
          header: 'Skill Mentor',
          align: 'right',
          width: 100,
          render: ({row}) => {
            const {assigned_to} = row;
            return <UserAvatar value={assigned_to?.skill_leader} />;
          },
        },
        {
          header: 'Last Rating',
          render: lastRating,
          width: 100,
        },
      ]}
      moreActions={() => [
        updateCV({navigation, params}),
        updateResourceSkill({navigation, params}),
        assignAsReplacement({navigation, params}),
        proposedDeploymentDate({navigation, params}),
        assignTraining({navigation, params}),
        markNotAvailable({navigation, params}),
        markUnBillable({invoke}),
        assignAsLearning({navigation, params}),
        unMarkTraining({invoke}),
      ]}
    />
  );
};

export const AvailableBenchResourceForDeliveryOwners = props => {
  let {
    navigation,
    route: {params},
    tab,
  } = props;
  const {period, ...rest} = params;
  params = {
    ...rest,
  };
  if (tab == 'Available') {
    params.fromAvailableTab = true;
  } else if (tab == 'Unbilled Deployments') {
    params.fromUnbilledDeploymentsTab = true;
  } else if (tab == 'Unbilled Deployments Internal') {
    params.forInternalTab = true;
  }
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'EmployeeAssignment',
  });
  return (
    <Table
      eventSourceId={['EmployeeAssignment']}
      limit={2000}
      searchFields="name"
      addOnFilter={{...rest}}
      api={`/employees/bench`}
      onRowPress={props => {
        const {row} = props;
        navigation.navigate(`resource-allocations-list`, {
          row,
          employeeFilter: {employee: row?._id},
        });
      }}
      columns={[
        {
          header: 'Available resource',
          render: ({row}) => {
            const {assigned_to} = row;
            return (
              <UserAvatarWithNameAndEmail
                name={assigned_to?.name}
                color={assigned_to?.color}
                official_email_id={assigned_to?.official_email_id}
              />
            );
          },
          width: 240,
        },
        EXPERIENCE,
        SKILLS,
        {
          header: 'DO',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.delivery_owner',
        },
        {
          header: 'TL',
          width: 180,
          type: 'userAvatarChip',
          field: 'assigned_to.skill_leader',
        },
        {
          header: 'Avl. Hrs',
          align: 'right',
          render: renderAvailablitity,
          width: 70,
        },
        {
          header: 'Bench Days',
          width: 120,
          render: RenderBenchSince,
        },
        {
          header: 'Status',
          render: renderStatusForAll,
          width: 140,
        },
      ]}
      {...props}
      params={params}
    />
  );
};
