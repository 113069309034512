import React from 'react';
import {Table} from '../../../components/table/Table';
import {InlineForm} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {
  Col,
  Image,
  Linking,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {navigateToPr} from './RepositoriesTable';
import {Chip, Tag} from '../../../components/chip/Chip';
import {PrStatusChip} from '../../../components/chip/PrStatusChip';
import {useInvoke} from '../../../controllers/useInvoke';
import {LinkRenderer} from '../../../components/form/Renderers';
import {useTheme} from '@unthinkable/react-theme';
import {ColorChip} from '../../../components/chip/ColorChip';
import {useAppStateContext} from '../../../providers/AppState';
import {useFetchData} from '../../../controllers/useFetchData';
import useRealTimeSync from '../../../components/customHooks/useRealTimeSync';
import {DotSeparator} from '@unthinkable/react-layout';
import {
  ActivityIndicator,
  ThreeDotLoader,
} from '@unthinkable/react-activity-indicator';
import {Confirm} from '../../../components/confirm/Confirm';

const TestCaseCountText = ({total, pass, fail}) => {
  const colors = useTheme('colors');
  const fonts = useTheme('fonts');
  return (
    <Row style={{alignItems: 'center', gap: 4}}>
      <Text style={{...fonts.CAPTION_LARGE, color: colors.NEUTRAL_LOW}}>
        {`${total} total`}
      </Text>
      <DotSeparator />
      <Text style={{...fonts.CAPTION_LARGE, color: colors.SUCCESS_HIGH}}>
        {`${pass} Pass`}
      </Text>
      <DotSeparator />
      <Text style={{...fonts.CAPTION_LARGE, color: colors.ERROR_HIGH}}>
        {`${fail} Fail`}
      </Text>
    </Row>
  );
};

let jenkinsJobStatusColors = {
  started: {
    color: 'SURFACE2',
    textColor: 'NEUTRAL_MEDIUM',
  },
  'in progress': {
    color: 'SURFACE2',
    textColor: 'NEUTRAL_MEDIUM',
  },
  completed: {
    color: 'SUCCESS_LOW',
    textColor: 'SUCCESS_HIGH',
  },
  failed: {
    color: 'ERROR_LOW',
    textColor: 'ERROR_HIGH',
  },
  Failed: {
    color: 'ERROR_LOW',
    textColor: 'ERROR_HIGH',
  },
  success: {
    color: 'SUCCESS_LOW',
    textColor: 'SUCCESS_HIGH',
  },
  Success: {
    color: 'SUCCESS_LOW',
    textColor: 'SUCCESS_HIGH',
  },
  failure: {
    color: 'ERROR_LOW',
    textColor: 'ERROR_HIGH',
  },
};

const JobStatus = ({row}) => {
  let {jenkins_setup_status, error} = row;

  let jenkinSetupStatus = jenkins_setup_status?.toLowerCase();

  if (!jenkins_setup_status) {
    return null;
  }

  let colors = useTheme('colors');

  return (
    <ColorChip
      gap={1}
      value={jenkins_setup_status}
      color={jenkinsJobStatusColors[jenkinSetupStatus]?.color}
      textColor={jenkinsJobStatusColors[jenkinSetupStatus]?.textColor}
      title={jenkins_setup_status === 'Failed' ? error : ''}
      suffix={
        jenkinSetupStatus === 'in progress' ? (
          <View style={{marginTop: 6}}>
            <ThreeDotLoader color={colors.NEUTRAL_LOW} size={3} />
          </View>
        ) : (
          void 0
        )
      }
    />
  );
};

export const ScreenInlineForm = props => {
  const {
    navigation,
    route: {params = {}},
  } = props;

  const {project_id} = params;

  let {onSubmit} = useFormSubmit({
    eventSourceId: 'View',
    uri: '/projectAssetVersions',
    ...props,
  });

  return (
    <InlineForm
      onSubmit={onSubmit}
      submitAction="Save"
      layoutFields={[
        {
          label: 'Environment',
          field: 'environment_id',
          type: 'autoComplete',
          api: '/projectEnvironments',
          filter: {
            project_id,
          },
          suggestionField: 'label',
          valueField: 'label',
          searchFields: 'label',
          required: true,
          onCreate: ({searchValue}) => {
            navigation.navigate(`project-environment-add-form`, {
              ...params,
              searchValue,
            });
          },
        },
      ]}
    />
  );
};

export const RepositoriesEnvironmentTable = props => {
  const {
    navigation,
    route: {params},
  } = props;

  const {
    project_id,
    repository_id,
    project: {
      owner_id: {_id: ownerId},
    },
    repository: {type: repository_type},
  } = params;

  const {user, download} = useAppStateContext();

  const icons = useTheme('icons');

  const {createPr} = navigateToPr();

  const invoke = useInvoke({
    eventSourceId: 'Environments',
  });

  const {data, setData, ...restFetchProps} = useFetchData({
    api: `/projects/${project_id}/repositories/${repository_id}/environments`,
    eventSourceId: 'Environments',
  });

  useRealTimeSync({
    event: 'JenkinSetupWebhookResponse',
    group: 'JenkinsResponse',
    callBack: ({
      repo_env_id,
      jenkins_setup_status,
      Job_Name,
      jenkins_deployment_url,
      live_url,
    }) => {
      setData(
        data.map(doc => {
          if (doc._id.toString() === repo_env_id.toString()) {
            doc.jenkins_setup_status = jenkins_setup_status;
            doc.pipeline = Job_Name;
            doc.jenkins_deployment_url = jenkins_deployment_url;
            doc.live_url = live_url;
          }
          return doc;
        }),
      );
    },
  });

  return (
    <Table
      data={data}
      {...restFetchProps}
      columns={[
        {
          width: 50,
          render: ({row}) => {
            const {deployed_histories_count} = row;
            let deploymentStatus =
              row?.recent_deployed_status?.status?.toLowerCase();
            let source;
            let title;
            if (!deployed_histories_count) {
              source = icons.NotDeployed;
              title = 'Not Deployed';
            } else if (deploymentStatus === 'success') {
              source = icons.DeploymentStatusSuccess;
              title = 'Build Success';
            } else if (deploymentStatus === 'failure') {
              source = icons.DeploymentStatusFailed;
              title = 'Build Failed';
            }
            let statusComponent;
            if (source) {
              statusComponent = (
                <View title={title}>
                  <Image style={{height: 20, width: 20}} source={source} />
                </View>
              );
            } else if (deploymentStatus === 'in progress') {
              statusComponent = (
                <View title={'In Progress'}>
                  <ActivityIndicator size={20} />
                </View>
              );
            }
            return statusComponent;
          },
        },
        {
          header: 'Environment',
          field: 'environment_id.label',
          colorField: 'environment_id.color',
          type: 'colorTag',
          width: 200,
        },
        {header: 'Branch', field: 'branch_name', type: 'text', width: 150},
        {
          header: 'Job Name',
          field: 'pipeline',
          type: 'text',
        },
        {
          header: 'PR Status',
          render: ({row}) => {
            const {
              pr_status,
              pr_status_link,
              open_pr_count = 0,
              branch,
            } = row || {};

            if (!pr_status) {
              return null;
            }

            const openPRTable = filter => {
              navigation.navigate('pull-request-table', {
                ...params,
                filter,
                secondaryTitle: row.environment_id
                  ? `Environment : ${row.environment_id.label}`
                  : void 0,
              });
            };

            return (
              <TouchableOpacity
                onPress={() => {
                  if (open_pr_count === 1) {
                    pr_status_link && Linking.openURL(pr_status_link, '_blank');
                  } else {
                    openPRTable({parent_branch_id: branch});
                  }
                }}>
                {open_pr_count ? (
                  <Tag
                    displayTextColor
                    color={'Information'}
                    value={`${open_pr_count} Pending`}
                  />
                ) : (
                  <PrStatusChip value={pr_status} />
                )}
              </TouchableOpacity>
            );
          },
          width: 150,
          align: 'center',
        },
        {
          header: 'Promote',
          width: 100,
          align: 'center',
          render: ({row}) => {
            let {last_success_status, environment_id = {}, repository_id} = row;

            const {environment, target_environment} = environment_id;
            let targetEnvironmentName = target_environment?.[0]?.environment;

            if (
              !environment ||
              !targetEnvironmentName ||
              last_success_status?.status !== 'Success'
            ) {
              return;
            }

            return (
              <TouchableOpacity
                onPress={async () => {
                  await createPr({
                    repository_id,
                    source_environment: environment,
                    target_environment: targetEnvironmentName,
                  });
                }}>
                <Tag value="Promote" />
              </TouchableOpacity>
            );
          },
        },
        {
          header: 'TestCases',
          render: ({row}) => {
            const {
              test_case_status,
              total_test_cases,
              passed_test_cases,
              failed_test_cases,
            } = row;

            if (!test_case_status) {
              return void 0;
            }

            let testCaseStatus = test_case_status?.toLowerCase();

            return (
              <Col style={{alignItems: 'flex-start'}} gap={12}>
                <Chip
                  displayTextColor
                  value={testCaseStatus === 'pass' ? 'Pass' : 'Fail'}
                  color={testCaseStatus === 'pass' ? 'success' : 'error'}
                />
                <TestCaseCountText
                  total={total_test_cases}
                  pass={passed_test_cases}
                  fail={failed_test_cases}
                />
              </Col>
            );
          },
          width: 180,
        },
        {
          width: 150,
          align: 'center',
          render: ({row}) => {
            if (row?.jenkins_deployment_url) {
              return (
                <Confirm
                  title="Deploy on Jenkins"
                  message="Are you sure you want deploy on Jenkins?"
                  confirmText="Deploy"
                  onConfirm={() => {
                    invoke({
                      uri: `/repositoryEnvironments/${row._id}/deployOnJenkin`,
                      props: {},
                      message: 'Deployment in Progress.',
                    });
                  }}>
                  <View title={'Deploy'}>
                    <Image
                      style={{height: 20, width: 20}}
                      source={icons.Rocket}
                    />
                  </View>
                </Confirm>
              );
            }

            return (
              <Col>
                <JobStatus row={row} />
              </Col>
            );
          },
        },
        {
          width: 50,
          align: 'center',
          render: ({row}) => {
            if (!row.live_url) {
              return;
            }
            return (
              <LinkRenderer
                title={'Live URL'}
                value={row.live_url}
                icon={icons.ArrowSquareOut}
              />
            );
          },
        },
      ]}
      moreActions={[
        // {
        //   title: 'Setup Infrastructure',
        //   onPress: ({row}) => {
        //     navigation.navigate('infrastructure-setup', {
        //       ...params,
        //       environment: row,
        //     });
        //   },
        //   // visible: ({row}) =>
        //   //   !(row.jenkins_deployment_url && row.deployment_platform_id),
        // },
        {
          title: 'Create Jenkins Pipeline',
          onPress: ({row}) => {
            navigation.navigate('jenkins-setup-form', {
              ...params,
              repository_type,
              environment: row,
              repository_id,
            });
          },
          // visible: ({row}) => ownerId === user.employee?._id,
          //   && !(row.jenkins_deployment_url && row.deployment_platform_id),
        },
        {
          title: 'Upload Docker and Environment',
          onPress: ({row}) => {
            navigation.navigate('upload-env-file', {
              ...params,
              environment: row,
            });
          },
          // visible: ({row}) => ownerId === user.employee?._id,
          //   && row.infra_setup_status === 'Success',
        },
        {
          title: 'Map domain',
          onPress: ({row}) => {
            navigation.navigate('domain-map', {
              ...params,
              environment: row,
            });
          },
        },
        {
          title: 'Edit Jenkins',
          onPress: ({row}) => {
            navigation.navigate('sync-jenkins-pipeline', {
              ...params,
              environment: row,
            });
          },
          // visible: ({row}) => ownerId === user.employee?._id,
          // visible: ({row}) =>
          //   !(row.jenkins_deployment_url && row.deployment_platform_id),
        },
        {
          title: 'View logs',
          onPress: ({row}) => {
            navigation.navigate('deployment-logs', {
              ...params,
              env_id: row._id,
              status: row?.recent_deployed_status?.status?.toLowerCase(),
              build_number: row.recent_deployed_status?.build_number,
            });
          },
        },
        {
          title: 'Download Testcase Report',
          onPress: () => {
            download({
              uri: `/projects/${project_id}/downloadTestcase`,
            });
          },
          visible: ({row}) => row.test_case_status,
        },
        {
          title: 'Deployment History',
          onPress: ({row}) => {
            navigation.navigate('deployment-histories', {
              ...params,
              environment: {_id: row._id, environment_id: row.environment_id},
            });
          },
          // visible: ({row}) => row.jenkins_deployment_url,
        },
      ]}
    />
  );
};
