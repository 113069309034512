import {Text} from '@unthinkable/react-core-components';
import {getDateRange} from '@unthinkable/react-date-picker/src/DateUtility';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {RowWithSeparator} from '@unthinkable/react-layout';
import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {AddAction} from '../../../components/action/AddAction';
import {IconButton} from '../../../components/button/IconButton';
import {GroupContentItem} from '../../../components/card/GroupContentItem';
import {PrimaryColumnCard} from '../../../components/card/PrimaryColumnCard';
import {MultiColorChip} from '../../../components/chip/MultiColorChip';
import {
  GroupFilter,
  PeriodRangeFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {TextRenderer} from '../../../components/form/Renderers';
import {TabView} from '../../../components/tab/TabView';
import {GroupTable, Table} from '../../../components/table/Table';
import {useFilter} from '../../../controllers/useFilter';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {randomColor} from '../../../utils/util';
import {
  ExitCategoryAvatar,
  ServiceDurationAvatar,
  UserAvatarWithNameAndEmail,
  UserAvatarWithNameAndEmployeeCode,
  UserAvatarWithNameCodeJoiningDate,
} from '../../reimbursement/components/ReimbursementColumnRenders';
import {employeeController} from '../controllers/employeeController';
import {LegalEmployeeWithPackage} from './EmployeeWithPackage';
import { moduleDevelopmentAssignment } from './UnavailableList';

export const RenderViewAllocations = props => {
  const {
    styles: {rowText},
  } = props;
  const colors = useTheme('colors');
  return (
    <TextRenderer
      value="View Allocations"
      style={{...rowText, color: colors['ACCENT1_HIGH']}}
    />
  );
};

const markIdentifyForSwapping = ({invoke}) => ({
  title: 'Mark Anchor Resource',
  confirm: {
    title: 'Mark Anchor Resource',
    message: 'Do you want mark anchor resource of this employee ?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        mark_identify_for_swapping: 'Pending',
      },
    });
  },
});

const markPotentialPIP = ({invoke}) => ({
  title: 'Mark Potential PIP',
  confirm: {
    title: 'Mark Potential PIP',
    message: 'Do you want to mark this employee as Potential PIP?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/employees/${row?._id}`,
      props: {
        potential_pip: true,
      },
    });
  },
});

const RenderProject = props => {
  const {
    row,
    styles: {rowText = {}},
  } = props;
  const projectName = row?.project
    ?.map(element => {
      return element?.project;
    })
    .join(', ');
  if (!projectName) return null;
  return (
    <PrimaryColumnCard
      primaryTitle={projectName}
      items={[
        {
          value: row?.order?.[0]?.order_type == 'r' ? 'T&M' : 'Fixed',
        },
        {
          value: `${row?.totalActualWorkingHours} h`,
        },
      ]}
    />
  );
};

export const EmployeeList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter: filterValues = {},
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  let addOnFilter = {};
  if (_id) {
    addOnFilter._id = _id;
  }
  const {markPip, markPotentialPip, EmployeeDetails} =
    employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'allocatedResource',
  });
  return (
    <Table
      {...props}
      params={params}
      addOnFilter={{...addOnFilter, ...filterValues}}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      searchFields={['name', 'employee_code']}
      api={api ? api : `/employees/active`}
      selection={{
        actions: [
          {
            title: 'Assign Team',
            onPress: ({selectedIds}) =>
              navigation.navigate('update-employee-teams', {
                ...params,
                employeeIds: selectedIds,
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
                primarySubTitle={row?.type}
                primarySubTitleColor={row?.types_color}
              />
            );
          },
          minWidth: 220,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          width: 80,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          align: 'center',
          width: 200,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.resource_language?.name}
                items={[
                  {
                    value: row?.seconday_resource_language?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Team',
          field: 'employee_team.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Designation',
          width: 200,
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.designation?.name}
                // items={[
                //   {
                //     value: row?.employee_team?.name,
                //   },
                // ]}
              />
            );
          },
        },
        {
          header: 'Project Reportee',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 140,
          onPress: ({row}) => {
            navigation.navigate('employee-project-reportee-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-project-reportee', {
                    row,
                  });
                }}
              />
            );
          },
        },
        {
          header: 'Primary Reportee',
          align: 'center',
          field: 'delivery_owner',
          type: 'userAvatar',
          width: 140,
          onPress: ({row}) => {
            navigation.navigate('employee-delivery-owner-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-delivery-owner', {
                    row,
                  });
                }}
              />
            );
          },
        },
        // {
        //   header: 'TL',
        //   align: 'center',
        //   field: 'skill_leader',
        //   type: 'userAvatar',
        //   width: 60,
        //   onPress: ({row}) => {
        //     navigation.navigate('employee-team-lead-list', {
        //       row,
        //     });
        //   },
        //   fallbackRender: ({row}) => {
        //     const {GreenBackGroundAdd} = useTheme('icons');
        //     return (
        //       <AddAction
        //         icon={GreenBackGroundAdd}
        //         onPress={() => {
        //           navigation.navigate('add-team-leader', {
        //             row,
        //           });
        //         }}
        //       />
        //     );
        //   },
        // },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: EmployeeDetails,
        },
        {
          title: 'Mark Potential PIP',
          onPress: markPotentialPip,
        },
        {
          title: 'Change Resource Skill',
          onPress: ({row}) => {
            navigation.navigate('edit-resource-skill', {row: row});
          },
        },
        {
          title: 'Update Practice',
          onPress: ({row}) => {
            navigation.navigate('edit-resource-practice', {row: row});
          },
        },
        {
          title: 'View Allocation',
          onPress: ({row}) => {
            navigation.navigate('resource-allocations-list', {
              ...params,
              employeeFilter: {
                employee: row?._id,
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'approved_by', select: {name: 1}},
                {
                  path: 'rejected_by',
                  select: {name: 1},
                },
                {
                  path: 'created_by',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
        // {
        //   title: 'Add Skills',
        //   onPress: ({row}) => {
        //     navigation.navigate('add-skill-to-employee', {
        //       ...params,
        //       employee: row,
        //     });
        //   },
        // },
        {
          title: 'Billing Status Report',
          onPress: ({row}) => {
            navigation.navigate('employeewise-billing-status-report', {
              extraAddOnFilterDeliveryOwner: {
                delivery_owner: row?._id,
              },
              extraAddOnFilterSkillLeader: {
                skill_leader: row?._id,
              },
            });
          },
        },
        {
          title: 'Update Team',
          onPress: ({row}) => {
            navigation.navigate('update-employee-team', {
              row,
            });
          },
        },
        {
          title: 'Add To Training',
          onPress: ({row}) => {
            navigation.navigate('add-to-training', {
              employee: row.assigned_to,
            });
          },
        },
        moduleDevelopmentAssignment({navigation, params}),
        // markIdentifyForSwapping({invoke}),
      ]}
    />
  );
};

export const SkillWiseEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  const {markPotentialPip, EmployeeDetails} = employeeController(props);

  return (
    <Table
      {...props}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      searchFields={['name', 'employee_code']}
      api={'/skillWiseEmployees'}
      selection={{
        actions: [
          {
            title: 'Assign Team',
            onPress: ({selectedIds}) =>
              navigation.navigate('update-employee-teams', {
                ...params,
                employeeIds: selectedIds,
              }),
          },
        ],
      }}
      groupRow={{
        data: '_children',
        defaultExpanded: false,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.resource_language?.name} />
              <GroupContentItem value={row?.count} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
                primarySubTitle={row?.type}
                primarySubTitleColor={row?.types_color}
              />
            );
          },
          minWidth: 250,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.resource_language?.name}
                items={[
                  {
                    value: row?.seconday_resource_language?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Team',
          field: 'employee_team.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Designation',
          width: 180,
          render: ({row}) => {
            return <PrimaryColumnCard primaryTitle={row?.designation?.name} />;
          },
        },
        {
          header: 'Reporting',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('employee-project-reportee-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-project-reportee', {
                    row,
                  });
                }}
              />
            );
          },
        },
        {
          header: 'DO',
          align: 'center',
          field: 'delivery_owner',
          type: 'userAvatar',
          width: 60,
          onPress: ({row}) => {
            navigation.navigate('employee-delivery-owner-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-delivery-owner', {
                    row,
                  });
                }}
              />
            );
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: EmployeeDetails,
        },
        {
          title: 'Mark Potential PIP',
          onPress: markPotentialPip,
        },
        {
          title: 'Change Resource Skill',
          onPress: ({row}) => {
            navigation.navigate('edit-resource-skill', {row: row});
          },
        },
        {
          title: 'Update Practice',
          onPress: ({row}) => {
            navigation.navigate('edit-resource-practice', {row: row});
          },
        },
        {
          title: 'View Allocation',
          onPress: ({row}) => {
            navigation.navigate('resource-allocations-list', {
              ...params,
              employeeFilter: {
                employee: row?._id,
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'approved_by', select: {name: 1}},
                {
                  path: 'rejected_by',
                  select: {name: 1},
                },
                {
                  path: 'created_by',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
        {
          title: 'Billing Status Report',
          onPress: ({row}) => {
            navigation.navigate('employeewise-billing-status-report', {
              extraAddOnFilterDeliveryOwner: {
                delivery_owner: row?._id,
              },
              extraAddOnFilterSkillLeader: {
                skill_leader: row?._id,
              },
            });
          },
        },
      ]}
    />
  );
};

export const RoleWiseEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;

  const {markPotentialPip, EmployeeDetails} = employeeController(props);

  return (
    <Table
      {...props}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      searchFields={['name', 'employee_code']}
      api={'/roleWiseEmployees'}
      selection={{
        actions: [
          {
            title: 'Assign Team',
            onPress: ({selectedIds}) =>
              navigation.navigate('update-employee-teams', {
                ...params,
                employeeIds: selectedIds,
              }),
          },
        ],
      }}
      groupRow={{
        data: '_children',
        defaultExpanded: false,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row?.department_role?.name} />
              <GroupContentItem value={row?.count} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                joining_date={row?.joining_date}
                primarySubTitle={row?.type}
                primarySubTitleColor={row?.types_color}
              />
            );
          },
          minWidth: 250,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.resource_language?.name}
                items={[
                  {
                    value: row?.seconday_resource_language?.name,
                  },
                ]}
              />
            );
          },
        },
        {
          header: 'Team',
          field: 'employee_team.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Designation',
          width: 180,
          render: ({row}) => {
            return <PrimaryColumnCard primaryTitle={row?.designation?.name} />;
          },
        },
        {
          header: 'Reporting',
          field: 'reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
          width: 100,
          onPress: ({row}) => {
            navigation.navigate('employee-project-reportee-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-project-reportee', {
                    row,
                  });
                }}
              />
            );
          },
        },
        {
          header: 'DO',
          align: 'center',
          field: 'delivery_owner',
          type: 'userAvatar',
          width: 60,
          onPress: ({row}) => {
            navigation.navigate('employee-delivery-owner-list', {
              row,
            });
          },
          fallbackRender: ({row}) => {
            const {GreenBackGroundAdd} = useTheme('icons');
            return (
              <AddAction
                icon={GreenBackGroundAdd}
                onPress={() => {
                  navigation.navigate('add-delivery-owner', {
                    row,
                  });
                }}
              />
            );
          },
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: EmployeeDetails,
        },
        {
          title: 'Mark Potential PIP',
          onPress: markPotentialPip,
        },
        {
          title: 'Change Resource Skill',
          onPress: ({row}) => {
            navigation.navigate('edit-resource-skill', {row: row});
          },
        },
        {
          title: 'Update Practice',
          onPress: ({row}) => {
            navigation.navigate('edit-resource-practice', {row: row});
          },
        },
        {
          title: 'View Allocation',
          onPress: ({row}) => {
            navigation.navigate('resource-allocations-list', {
              ...params,
              employeeFilter: {
                employee: row?._id,
              },
            });
          },
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'approved_by', select: {name: 1}},
                {
                  path: 'rejected_by',
                  select: {name: 1},
                },
                {
                  path: 'created_by',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
        {
          title: 'Billing Status Report',
          onPress: ({row}) => {
            navigation.navigate('employeewise-billing-status-report', {
              extraAddOnFilterDeliveryOwner: {
                delivery_owner: row?._id,
              },
              extraAddOnFilterSkillLeader: {
                skill_leader: row?._id,
              },
            });
          },
        },
      ]}
    />
  );
};

export const InActiveEmployeeList = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  return (
    <Table
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name']}
      api={`/employees/inactive`}
      selection={{
        actions: [
          {
            title: 'Update Team',
            onPress: ({selectedIds}) =>
              navigation.navigate('update-employee-teams', {
                ...params,
                employeeIds: selectedIds,
              }),
          },
        ],
      }}
      columns={[
        {
          header: 'Resource',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.assigned_to?.name}
                color={row?.assigned_to?.color}
                official_email_id={row?.assigned_to?.official_email_id}
              />
            );
          },
          size: 260,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Skill',
          field: 'employee_skills.skill_metrics',
          labelField: 'name',
          titleField: 'name',
          colorField: 'color',
          render: MultiColorChip,
          maxChips: 1,
          numberOfLines: 1,
          width: 200,
        },
        {
          header: 'Project Allocations',
          render: RenderProject,
        },
        {
          header: 'Reporting',
          field: 'assigned_to.reporting_to',
          type: 'userAvatarGroup',
          align: 'center',
        },
        {
          header: 'Performance (Last Month)',
          field: 'rating_last_month',
        },
      ]}
      {...props}
    />
  );
};
export const InActiveEmployeeGroupTable = props => {
  let {
    route: {params},
    navigation,
  } = props;
  const {organization, product, period, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
  };
  const {EmployeeDetails} = employeeController(props);
  return (
    <GroupTable
      params={params}
      eventSourceId={['EmployeeAssignment', 'EmployeeSkills']}
      searchFields={['name', 'employee_code']}
      api={`/employees/inactive`}
      groupRow={{
        data: 'children',
        defaultExpanded: true,
        leftContent: ({row}) => {
          return (
            <RowWithSeparator style={{gap: 8, alignItems: 'center'}}>
              <GroupContentItem value={row.key} />
              <GroupContentItem value={`${row.count} Employee`} />
            </RowWithSeparator>
          );
        },
      }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameAndEmployeeCode
                name={row?.name}
                color={row?.color}
                employee_code={row?.employee_code}
                employee_skills={row?.employee_skills}
              />
            );
          },
          width: 260,
        },
        {
          header: 'Exp',
          field: 'experience',
          type: 'number',
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.experience && `${row?.experience} yr`}
              </Text>
            );
          },
          width: 100,
        },
        {
          header: 'Skill',
          align: 'center',
          render: ({row}) => {
            return (
              <PrimaryColumnCard
                primaryTitle={row?.resource_language?.name}
                items={[
                  {
                    value: row?.seconday_resource_language?.name,
                  },
                ]}
              />
            );
          },
        },
        // {
        //   header: 'Team',
        //   field: 'employee_team.name',
        //   type: 'colorTag',
        // },
        {
          header: 'Designation',
          field: 'designation.name',
          type: 'text',
        },
        {
          header: 'Service Duration',
          render: ({row}) => {
            return (
              <ServiceDurationAvatar
                service_duration={row?.service_duration}
                color={row?.color}
                relieving_date={row?.relieving_date}
                joining_date={row?.joining_date}
              />
            );
          },
          width: 180,
        },
        {
          header: 'Exit Category',
          render: ({row}) => {
            return (
              <ExitCategoryAvatar
                exit_type={row?.exit_type}
                color={row?.color}
                desireable_status={row?.desireable_status}
              />
            );
          },
          width: 130,
          align: 'left',
        },
      ]}
      moreActions={() => [
        {
          title: 'Details',
          onPress: EmployeeDetails,
        },
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'employee_team', select: {name: 1}},
                {
                  path: 'seconday_resource_language',
                  select: {name: 1},
                },
                {
                  path: 'designation',
                  select: {name: 1},
                },
              ],
              api: `/employees/${row?._id}/activityLogs`,
              displayFields: {
                name: {
                  label: 'Name',
                  field: 'name',
                },
                employee_team: {
                  label: 'Team',
                  field: 'employee_team.name',
                },
                designation: {
                  label: 'Designation',
                  field: 'designation.name',
                },
                seconday_resource_language: {
                  label: 'Secondary Skill',
                  field: 'seconday_resource_language.name',
                },
                created_at: {
                  label: 'Date',
                  field: 'created_at',
                },
              },
            });
          },
        },
      ]}
      {...props}
    />
  );
};

const getCurrentFY = () => {
  let {from, to} = getDateRange('currentFinancialYear');
  from = getZeroTimeDate(from);
  to = getZeroTimeDate(to, true);
  return {
    from,
    to,
    filterOf: 'FY',
  };
};

export const EmployeeTabs = props => {
  const {Upload, Download} = useTheme('icons');
  const {download} = useAppStateContext();
  let {route: {params} = {}, navigation} = props;
  const {organization, product, period, department, ...rest} = params;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      // period: params.period || getPreviousMonth(),
      period: getCurrentFY(),
    },
    filter: {
      department,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Active',
    api: '/employees/active',
    params: {
      ...params,
    },
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeList
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Primary Skill',
            api: '/tools',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'resource_language',
          },
          {
            type: 'autoComplete',
            label: 'Secondary Skill',
            api: '/tools',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'seconday_resource_language',
          },
          {
            type: 'autoComplete',
            label: 'Team Lead',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'skill_leader',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Project Reportee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Primary Reportee',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'delivery_owner',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Team',
            api: '/employeeteams',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee_team',
          },
        ]}
      />,
      <AddAction
        icon={Upload}
        onPress={() => {
          navigation.navigate('import-employee-deliveryowner-and-teamlead');
        }}
      />,
      <IconButton
        icon={Download}
        onPress={() => {
          download({
            uri: '/exportEmployeesData',
            props: {
              uri: '/employees/active',
              parameters: {
                params: {
                  ...params,
                },
                addOnFilter: filter,
              },
            },
          });
        }}
      />,
    ],
  };
  tabs.inactive = {
    label: 'Inactive',
    api: '/employees/inactive',
    params: {
      ...params,
    },
    addOnFilter: filter,
    limit: 5000,
    view: (
      <InActiveEmployeeGroupTable
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <PeriodRangeFilter
        onChangeFilter={onChangeFilter}
        filterValues={filterValues}
        asParam
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Team',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            placeholder: 'Select',
            options: [
              {label: 'Voluntary', value: 'Voluntary'},
              {label: 'Involuntary', value: 'Involuntary'},
            ],
            field: 'exit_type',
            label: 'Exit Category',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
          {
            type: 'autoComplete',
            placeholder: 'Select',
            options: [
              {label: 'Regrettable', value: 'Regrettable'},
              {label: 'Desirable', value: 'Desirable'},
            ],
            field: 'desireable_status',
            label: 'Desireable Status',
            suggestionField: 'label',
            keyField: 'value',
            valueField: 'label',
          },
        ]}
      />,
      <IconButton
        icon={Download}
        onPress={() => {
          download({
            uri: '/exportEmployeesData',
            props: {
              uri: '/employees/inactive',
              parameters: {
                ...params,
                fromInActiveTab: true,
                addOnFilter: filter,
              },
            },
          });
        }}
      />,
    ],
  };

  tabs.legal = {
    label: 'Legal',
    api: '/employees/legal-with-package',
    params: {
      ...params,
    },
    search: searchValue,
    searchFields: ['name', 'employee_code'],
    addOnFilter: filter,
    limit: 5000,
    view: (
      <LegalEmployeeWithPackage
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
        ]}
      />,
    ],
  };

  return <TabView tabs={tabs} {...props} />;
};

export const SkillWiseEmployeeTabs = props => {
  const {Upload, Download} = useTheme('icons');
  const {download} = useAppStateContext();
  let {
    route: {params},
    navigation,
  } = props;
  const {department} = params;
  // @ts-ignore
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
    filter: {
      department,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;

  const tabs = {
    active: {
      label: 'Employees',
      api: '/employees/active',
      params: {
        ...filterParams,
      },
      filter: {
        employee_status: 'Active',
      },
      addOnFilter: filter,
      limit: 5000,
      view: (
        <SkillWiseEmployeeList
          params={filterParams}
          search={searchValue}
          filter={{
            employee_status: 'Active',
          }}
          addOnFilter={filter}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Primary Skill',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Secondary Skill',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'seconday_resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
              filter: {employee_status: 'Active'},
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
              filter: {employee_status: 'Active'},
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              filter: {employee_status: 'Active'},
            },
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
            },
          ]}
        />,
        <AddAction
          icon={Upload}
          onPress={() => {
            navigation.navigate('import-employee-deliveryowner-and-teamlead');
          }}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportEmployeesData',
              props: {
                uri: '/skillWiseEmployees',
                parameters: {
                  params: {
                    ...filterParams,
                  },
                  filter: {
                    employee_status: 'Active',
                  },
                  addOnFilter: filter,
                },
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView params={{...params, ...filterParams}} tabs={tabs} {...props} />
  );
};

export const RoleWiseEmployeeTabs = props => {
  const {Upload, Download} = useTheme('icons');
  const {download} = useAppStateContext();
  let {
    route: {params},
    navigation,
  } = props;
  const {department} = params;
  // @ts-ignore
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
    filter: {
      department,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;

  const tabs = {
    active: {
      label: 'Employees',
      api: '/employees/active',
      params: {
        ...filterParams,
      },
      filter: {
        employee_status: 'Active',
      },
      addOnFilter: filter,
      limit: 5000,
      view: (
        <RoleWiseEmployeeList
          params={filterParams}
          search={searchValue}
          filter={{
            employee_status: 'Active',
          }}
          addOnFilter={filter}
        />
      ),
      actions: [
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Product',
              api: '/products',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'product',
            },
            {
              type: 'autoComplete',
              label: 'Primary Skill',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Secondary Skill',
              api: '/tools',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'seconday_resource_language',
            },
            {
              type: 'autoComplete',
              label: 'Delivery Owner',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'delivery_owner',
              filter: {employee_status: 'Active'},
            },
            {
              type: 'autoComplete',
              label: 'Team Lead',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'skill_leader',
              filter: {employee_status: 'Active'},
            },
            {
              type: 'autoComplete',
              label: 'Reporting To',
              api: '/employeeSuggestions',
              placeholder: 'Select',
              suggestionField: 'name',
              secondarySuggestionField: 'official_email_id',
              valueField: 'name',
              field: 'reporting_to',
              filter: {employee_status: 'Active'},
            },
            {
              type: 'autoComplete',
              label: 'Team',
              api: '/employeeteams',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'employee_team',
            },
          ]}
        />,
        <AddAction
          icon={Upload}
          onPress={() => {
            navigation.navigate('import-employee-deliveryowner-and-teamlead');
          }}
        />,
        <IconButton
          icon={Download}
          onPress={() => {
            download({
              uri: '/exportEmployeesData',
              props: {
                uri: '/skillWiseEmployees',
                parameters: {
                  params: {
                    ...filterParams,
                  },
                  filter: {
                    employee_status: 'Active',
                  },
                  addOnFilter: filter,
                },
              },
            });
          }}
        />,
      ],
    },
  };

  return (
    <TabView params={{...params, ...filterParams}} tabs={tabs} {...props} />
  );
};

export const DepartmentWiseEmployeeTabs = props => {
  const {Upload, Download} = useTheme('icons');
  const {download} = useAppStateContext();
  let {route: {params} = {}, navigation} = props;
  const {organization, product, period, department, ...rest} = params;
  let {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter({
    params: {
      period: getCurrentFY(),
    },
    filter: {
      department,
    },
  });
  const {filter, params: filterParams = {}} = filterValues;
  params = {
    ...filterParams,
    ...rest,
    product,
    organization,
  };

  let tabs = {};
  tabs.active = {
    label: 'Active',
    api: '/employees/active',
    params: {
      ...params,
    },
    addOnFilter: filter,
    limit: 5000,
    view: (
      <EmployeeList
        params={{...params, ...filterParams}}
        search={searchValue}
        addOnFilter={filter}
      />
    ),
    actions: [
      <SearchFilter
        value={searchValue}
        onChangeFilter={onChangeFilter}
        placeholder="Search"
      />,
      <GroupFilter
        filterValues={filterValues}
        applyFilter={applyFilter}
        filters={[
          {
            type: 'autoComplete',
            label: 'Department',
            api: '/departments',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'department',
          },
          {
            type: 'autoComplete',
            label: 'Product',
            api: '/products',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'product',
          },
          {
            type: 'autoComplete',
            label: 'Primary Skill',
            api: '/tools',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'resource_language',
          },
          {
            type: 'autoComplete',
            label: 'Secondary Skill',
            api: '/tools',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'seconday_resource_language',
          },
          {
            type: 'autoComplete',
            label: 'Delivery Owner',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'delivery_owner',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Team Lead',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'skill_leader',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Reporting To',
            api: '/employeeSuggestions',
            placeholder: 'Select',
            suggestionField: 'name',
            secondarySuggestionField: 'official_email_id',
            valueField: 'name',
            field: 'reporting_to',
            filter: {employee_status: 'Active'},
          },
          {
            type: 'autoComplete',
            label: 'Team',
            api: '/employeeteams',
            placeholder: 'Select',
            suggestionField: 'name',
            valueField: 'name',
            field: 'employee_team',
          },
        ]}
      />,
      <AddAction
        icon={Upload}
        onPress={() => {
          navigation.navigate('import-employee-deliveryowner-and-teamlead');
        }}
      />,
      <IconButton
        icon={Download}
        onPress={() => {
          download({
            uri: '/exportEmployeesData',
            props: {
              uri: '/employees/active',
              parameters: {
                params: {
                  ...params,
                },
                addOnFilter: filter,
              },
            },
          });
        }}
      />,
    ],
  };

  return <TabView tabs={tabs} {...props} />;
};

export const EmployeeSkillTable = props => {
  const {
    route: {params},
  } = props;
  const {skill_metrics} = params;
  return (
    <EmployeeList
      api={`/skillMetrics/${skill_metrics?._id}/employee-with-skills`}
      {...props}
    />
  );
};

export const EmployeeIncentiveScheduleList = props => {
  let {
    route: {params},
    navigation,
    addOnFilter,
    params: propsParams,
  } = props;
  const {organization, product, period, _id, api, ...rest} = params;
  params = {
    ...rest,
    product,
    organization,
    propsParams,
  };
  const {markPip, markPotentialPip, EmployeeDetails} =
    employeeController(props);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'allocatedResource',
  });
  return (
    <Table
      {...props}
      params={params}
      addOnFilter={{...addOnFilter}}
      eventSourceId={[
        'EmployeeAssignment',
        'EmployeeSkills',
        'employeeassociation',
      ]}
      searchFields={['name', 'employee_code']}
      api={api ? api : `/incentive-schedule`}
      // selection={{
      //   actions: [
      //     {
      //       title: 'Assign Team',
      //       onPress: ({selectedIds}) =>
      //         navigation.navigate('update-employee-teams', {
      //           ...params,
      //           employeeIds: selectedIds,
      //         }),
      //     },
      //   ],
      // }}
      columns={[
        {
          header: 'Employee',
          render: ({row}) => {
            return (
              <UserAvatarWithNameCodeJoiningDate
                name={row?.employee?.name}
                color={row?.employee?.color}
                employee_code={row?.employee?.employee_code}
              />
            );
          },
          // width: 280,
        },
        {
          header: 'Exp',
          field: 'employee.experience',
          type: 'number',
          align: 'center',
          width: 100,
          render: ({row = {}, styles: {rowText = {}}}) => {
            return (
              <Text style={{...rowText}}>
                {row?.employee?.experience && `${row?.employee?.experience} yr`}
              </Text>
            );
          },
        },
        {
          header: 'Salary Component',
          field: 'fix_base_components.salary_component_id.name',
          type: 'text',
          width: 250,
        },
        {
          header: 'Effective From',
          field: 'fix_base_components.from_date',
          type: 'date',
          width: 180,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Effective To',
          field: 'fix_base_components.to_date',
          type: 'date',
          widh: 180,
          formatOptions: {
            format: 'DD MMM YY',
          },
        },
        {
          header: 'Amount',
          field: 'fix_base_components.amount',
          type: 'currency',
          widh: 180,
        },
        {
          header: 'Remark',
          field: 'fix_base_components.remarks',
          type: 'text',
          widh: 180,
        },
      ]}
    />
  );
};
