import React from 'react';
import { AddButton } from '../../../components/button/AddButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TableHeader } from '../../../components/table/Headers';
import { Table } from '../../../components/table/Table';
import { useFilter } from '../../../controllers/useFilter';
import { UserAvatarWithNameAndEmail } from '../../reimbursement/components/ReimbursementColumnRenders';
import { TabView } from '../../../components/tab';
import { useInvoke } from '../../../controllers/useInvoke';
import { useAppStateContext } from '../../../providers/AppState';
import { getCurrentMonth } from '@unthinkable/react-filters';
import { UploadButton } from '../../leadGeneration/buttons/Upload';
import { Button } from '../../../components/button/Button';


export const ManualVariableTab = props => {
  let { user = {}, route: { params = {} } = {}, fromHr, ...rest } = props || {};
  const { applyFilter, filterValues, searchValue } = useFilter({
    params: {
      period: getCurrentMonth(),
    },
  });
  const { navigation } = rest;
  const { employee = {} } = user;
  const {department_access = []} = employee;
  let department = ["xxxx"];
  if(department_access?.length){
    department = department_access.map((dep) => dep._id)
  }
  const addOnFilter = {};
  if (!fromHr && department) {
    addOnFilter["employee_id.employee_status"] = "Active"
    addOnFilter["employee_id.department"] = {$in : department}
  }
  const { filter, params: { period = {} } = {} } = filterValues || {};
  params = { ...params, user };
  let tabs = {};
  tabs['Pending'] = {
    label: 'Pending',
    eventSourceId: 'manualVariable',
    view: <ManualVariableTable
      searchValue={searchValue}
      filterValues={filterValues}
      addOnFilter={{
        ...filterValues.filter,
        ...addOnFilter,
        status: 'pending'
      }}
      api='/manualVariable/setup'
      user={user}
      fromHr
      {...rest}
      tab="Pending"
    />,
    addOnFilter: {
      ...filter,
      ...addOnFilter,
      status: 'pending'
    },
    api: '/manualVariable/setup',
  }

  tabs['Approved'] = {
    label: 'Approved',
    eventSourceId: 'manualVariable',
    view: <ManualVariableTable
      searchValue={searchValue}
      filterValues={filterValues}
      addOnFilter={{
        ...filterValues.filter,
        ...addOnFilter,
        status: 'approved'
      }}
      fromHr
      api='/manualVariable/setup'
      user={user}
      {...rest}
      tab="Approved"
    />,
    addOnFilter: {
      ...filter,
      ...addOnFilter,
      status: 'approved'
    },
    api: '/manualVariable/setup',
  }

  tabs['Rejected'] = {
    label: 'Rejected',
    eventSourceId: 'manualVariable',
    view: <ManualVariableTable
      searchValue={searchValue}
      filterValues={filterValues}
      addOnFilter={{
        ...filterValues.filter,
        ...addOnFilter,
        status: 'rejected'
      }}
      fromHr
      api='/manualVariable/setup'
      user={user}
      {...rest}
      tab="Rejected"
    />,
    addOnFilter: {
      ...filter,
      ...addOnFilter,
      status: 'rejected'
    },
    api: '/manualVariable/setup',
  }
  let importVariable;
  if(fromHr){
      importVariable = <Button
      text="Import Variable"
      onPress={() => {
        navigation.navigate('import-list', {
          source: 'manualvariable',
          model: 'VariableSalary',
        });
      }}
    />
  }

  return <TabView
    tabs={tabs}
    // params={params}
    {...props}
    actions={[
      <AddButton
        title="Manual Variable"
        view="add-manual-variable"
        params={{ ...params, fromHr }}
      />,
      importVariable,
      <GroupFilter
      filterValues={filterValues}
      applyFilter={applyFilter}
      filters={[
        {
          type: 'autoComplete',
          label: 'Employee',
          api: '/employeeSuggestions',
          placeholder: 'Select',
          suggestionField: 'name',
          valueField: 'name',
          field: 'employee_id',
          secondarySuggestionField: 'official_email_id',
          searchFields: ['name', 'official_email_id', 'employee_code'],
        },
        {
          type: 'date',
          field: 'from_date',
          label: 'From Date',
        },
        {
          type: 'date',
          field: 'to_date',
          label: 'To Date',
        },
      ]}
    />,
    ]}
  />;
}

export const ManualVariableTable = props => {
  const { navigation, route: { params } = {}, filter, addOnFilter, tab, fromHr } = props;
  const invoke = useInvoke({
    method: 'post',
    eventSourceId: 'manualVariable',
  });
  const { post, download } = useAppStateContext();
  const fetchProps = {
    params,
    addOnFilter: { ...filter, ...addOnFilter },
    api: '/manualVariable/setup',
  };
  return (
    <Table
      eventSourceId={['manualVariable']}
      api={`/manualVariable/setup`}
      addOnFilter={{ ...filter, ...addOnFilter }}
      selection={{
        actions: [
          {
            title: 'Download',
            visible: fromHr,
            onPress: props =>
              download({
                uri: '/exportExcelHelper',
                props: {
                  props: {
                    // ...props,
                    selectedIds: props.selectedIds,
                    fetchProps,
                    column: {
                      'Employee': 'employee_id.name',
                      'Code': 'employee_id.employee_code',
                      'From Date': 'from_date',
                      'To Date': 'to_date',
                      'Level 1': 'level1_achievement',
                      'Level 2': 'level2_achievement',
                      'Level 3': 'level3_achievement',
                      'Self': 'self_achievement',

                    },
                  },
                },
              }),
          },
          {
            title: 'Approve',
            onPress: ({ selectedIds }) => {
              invoke({
                uri: `/onApproveManualVariable`,
                props: {
                  selectedIds,
                },
              });
            },
            visible: tab && tab === "Pending" && fromHr
          },
          {
            title: 'Reject',
            onPress: ({ selectedIds }) => {
              invoke({
                uri: `/onRejectManualVariable`,
                props: {
                  selectedIds,
                },
              });
            },
            visible: tab && tab === "Pending" && fromHr
          },
        ],
      }}
      onRowPress={props => {
        const { row } = props;
        navigation.navigate(`edit-manual-variable`, {
          row,
        });
      }}
      columns={[
        {
          header: 'Employee',
          render: ({ row }) => {
            return (
              <UserAvatarWithNameAndEmail
                name={row?.employee_id?.name}
                color={row?.employee_id?.color}
                official_email_id={row?.employee_id?.official_email_id}
              />
            );
          },
          minWidth: 200,
        },
        {
          header: 'From Date',
          field: 'from_date',
          type: 'date',
          formatOptions: { format: 'DD MMM, YY' },
          width: 150,
        },
        {
          header: 'To Date',
          field: 'to_date',
          type: 'date',
          formatOptions: { format: 'DD MMM, YY' },
          width: 150,
        },
        {
          header: 'Level 1',
          field: 'level1_achievement',
          type: 'number',
          width: 150,
        },
        {
          header: 'Level 2',
          field: 'level2_achievement',
          type: 'number',
          width: 150,
        },
        {
          header: 'Level 3',
          field: 'level3_achievement',
          type: 'number',
          width: 150,
        },
        {
          header: 'Self',
          field: 'self_achievement',
          type: 'number',
          width: 150,
        },
      ]}
    />
  );
};
