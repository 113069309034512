import React from 'react';
import {Table} from '../../../components/table/Table';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {UserAvatarChip} from '../../../components/avatar/UserAvatar';
import {useFilter} from '@unthinkable/react-filters';
import {TabView} from '../../../components/tab';
import {GroupFilter, SearchFilter} from '../../../components/filter/Filters';

const DUE_ALLOCATIONS = ({navigation}) => ({
  render: CountCellRender,
  count_field: 'due_allocation_count',
  field: 'due_allocation_count',
  header: 'Requests',
  styleForCountField: true,
  align: 'right',
  onPress: ({row}) => {
    navigation.navigate('project-order-resource-request-table', {
      data: {order: row.order, project: row._id},
    });
  },
  aggregate: true,
});

const INTERNAL_DUE_ALLOCATIONS = ({navigation}) => ({
  render: CountCellRender,
  count_field: 'count',
  field: 'count',
  header: 'Requests',
  styleForCountField: true,
  align: 'right',
  onPress: ({row}) => {
    navigation.navigate('project-internal-resource-requests', {
      row,
    });
  },
  aggregate: true,
});

export const ProjectDueAllocationsTable = props => {
  const {
    route: {params = {}} = {},
    navigation,
    tab,
    searchValue,
    addOnFilter = {},
  } = props || {};

  return (
    <Table
      api={'/getProjectDueAllocations'}
      params={{...params, tab}}
      searchFields={'project'}
      search={searchValue}
      addOnFilter={{project_status: 'a', ...addOnFilter}}
      eventSourceId={[
        'skillRequirement',
        'employeeAssigned',
        'orderresourcerequests',
      ]}
      columns={[
        {header: 'Project', field: 'project', type: 'text', minWidth: 200},
        {
          header: tab == 'Billed' ? 'Cusotmer ' : 'For',
          field: 'customer.name',
          type: 'colorTag',
          color: '#F5E0FF',
          minWidth: 240,
        },
        {
          header: 'CSM/PM ',
          align: 'left',
          render: ({row}) => {
            return <UserAvatarChip value={row?.csm || row?.owner_id} />;
          },
        },
        DUE_ALLOCATIONS({navigation}),
      ]}
    />
  );
};

export const ProjectInternalDueAllocationsTable = props => {
  const {
    route: {params = {}} = {},
    navigation,
    tab,
    searchValue,
    addOnFilter = {},
  } = props || {};

  return (
    <Table
      api={'/internalrequests/groupByProject'}
      params={{...params, tab}}
      searchFields={'project'}
      search={searchValue}
      addOnFilter={{...addOnFilter}}
      eventSourceId={[
        'skillRequirement',
        'employeeAssigned',
        'orderresourcerequests',
      ]}
      columns={[
        {
          header: 'Project',
          field: 'project.project',
          type: 'text',
          minWidth: 200,
        },
        {
          header: tab == 'Billed' ? 'Cusotmer ' : 'For',
          field: 'customer.name',
          type: 'colorTag',
          color: '#F5E0FF',
          minWidth: 240,
        },
        {
          header: 'CSM/PM ',
          align: 'left',
          field: 'owner_id',
          type: 'userAvatarChip',
        },
        INTERNAL_DUE_ALLOCATIONS({navigation}),
      ]}
    />
  );
};

export const ProjectWiseAllocationDueTab = props => {
  let {route: {params} = {}} = props;
  const {filterValues, searchValue, onChangeFilter, applyFilter} = useFilter(
    {},
  );
  const {filter = {}} = filterValues || {};

  const tabs = {
    billed_project: {
      label: 'Billed Projects',
      view: (
        <ProjectDueAllocationsTable
          tab="Billed"
          addOnFilter={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
    },

    unbilled_project: {
      label: 'Un-Billed Projects',
      view: (
        <ProjectInternalDueAllocationsTable
          tab="Unbilled"
          addOnFilter={filter}
          searchValue={searchValue}
          {...props}
        />
      ),
    },
  };

  return (
    <TabView
      tabs={tabs}
      params={params}
      {...props}
      actions={[
        <SearchFilter
          value={searchValue}
          onChangeFilter={onChangeFilter}
          placeholder="Search"
        />,
        <GroupFilter
          filterValues={filterValues}
          applyFilter={applyFilter}
          filters={[
            {
              type: 'autoComplete',
              label: 'Customer',
              api: '/customers',
              placeholder: 'Select',
              suggestionField: 'name',
              valueField: 'name',
              field: 'customer',
            },
            {
              type: 'autoComplete',
              label: 'Project Type',
              options: [
                {value: 'tm', label: 'T&M'},
                {value: 'f', label: 'Fixed'},
              ],
              suggestionField: 'label',
              keyField: 'value',
              valueField: 'label',
              field: 'projecttype',
            },
          ]}
        />,
      ]}
    />
  );
};
