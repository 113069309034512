import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {Text, View} from '@unthinkable/react-core-components';
import React, {useState} from 'react';
import {Table} from '../../../components/table/Table';
import {useInvoke} from '../../../controllers/useInvoke';
import {useAppStateContext} from '../../../providers/AppState';
import {AmountRender} from '../../order/views/components/AmountRender';
import {Durations} from '../../order/views/components/Durations';

const RenderStatus = ({row, styles, navigation}) => {
  const [loading, setLoading] = useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'budget',
    close: false,
    setLoading,
  });
  let {rowText = {}} = styles;
  return (
    <>
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View style={{flexDirection: 'row'}}>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              invoke({
                uri: `/budgetrequests/${row?._id}`,
                props: {
                  status: 'Approved',
                  approved_date: new Date(),
                },
              });
            }}>
            <Text style={{...rowText, color: '#34C759'}}>Accept </Text>
          </View>
          <View
            style={{
              padding: 4,
              alignItem: 'center',
              textAlign: 'center',
            }}
            onPress={() => {
              navigation.navigate('reject-budget-request', {row});
            }}>
            <Text style={{...rowText, color: '#FF5247'}}>Reject</Text>
          </View>
        </View>
      )}
    </>
  );
};

export const BudgetApproverTable = props => {
  const {navigation, tab, addOnFilter, orgView} = props;
  let {
    user: {employee},
  } = useAppStateContext();
  let filter = {approver: employee._id};
  if (orgView) {
    filter = {};
  }
  return (
    <Table
      eventSourceId={['budget']}
      api={`/budget/budgetdetail`}
      addOnFilter={{...addOnFilter, ...filter}}
      onRowPress={({row}) => {
        navigation.navigate('edit-budget-request', {row, readOnly: true});
      }}
      columns={[
        {
          header: 'Requested On',
          field: 'request_date',
          type: 'date',
          width: 150,
        },
        {
          header: 'Team',
          field: 'team.name',
          type: 'colorTag',
          width: 150,
        },
        {
          header: 'Vendor',
          field: 'vendor.name',
          type: 'colorTag',
          minWidth: 250,
        },
        {
          header: 'Description',
          field: 'description',
          type: 'text',
          width: 300,
        },
        {
          header: 'Term',
          field: 'term',
          type: 'text',
          width: 100,
        },
        {
          header: 'Limit',
          field: 'amount',
          type: 'currency',
          width: 150,
          render: ({row, styles}) => {
            return (
              <AmountRender
                row={row}
                styles={styles}
                amount={row?.amount}
                currency={row?.currency?.currency}
              />
            );
          },
        },
        {
          header: 'Duration',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
          align: 'center',
          width: 200,
        },

        {
          header: 'Approver',
          field: 'approver',
          type: 'userAvatar',
          width: 100,
        },
        {
          header: 'Requester',
          field: 'requested_by',
          type: 'userAvatar',
          width: 100,
        },
        {
          header: 'Action',
          visible: ({values}) => {
            if (tab === 'BudgetApproverActive' && orgView) return true;
          },
          align: 'center',

          render: ({row, styles}) => {
            return (
              <RenderStatus row={row} styles={styles} navigation={navigation} />
            );
          },
        },
        {
          header: 'Status',
          visible: ({values}) => {
            if (tab === 'BudgetApproverClosed') return true;
          },
          render: ({row, styles}) => {
            let color = '';
            if (row?.status === 'Approved') {
              color = '#34C759';
            } else if (row?.status === 'Rejected') {
              color = '#FF5247';
            }
            return (
              <Text style={{...styles.rowText, color: color}}>
                {row.status}
              </Text>
            );
          },
        },
      ]}
      moreActions={[
        {
          title: 'Activity History',
          onPress: ({row}) => {
            navigation.navigate('activity-logs', {
              _id: row._id,
              populate: [
                {path: 'team', select: {name: 1}},
                {path: 'vendor', select: {name: 1}},
                {path: 'requester', select: {name: 1}},
                {path: 'approver', select: {name: 1}},
                {path: 'currency', select: {currency: 1}},
                {path: 'created_by', select: {name: 1}},
              ],
              api: `/budgetrequests/${row?._id}/activityLogs`,
            });
          },
        },
      ]}
    />
  );
};
