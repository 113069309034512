import React from 'react';
import {NavHeader} from '../components/nav/NavHeader';

import ModuleDetail from '../modules/pmt/views/ModuleDetail';
import ProjectHome from '../modules/pmt/views/ProjectHome';
import FeatureDetail from '../modules/pmt/views/FeatureDetail';
import ProjectTeamMenu from '../modules/common/views/ProjectTeamMenu.js';

import {AddVCSForm, UpdateVCSForm} from '../modules/pmt/views/VCSForm';
import {
  AddRepositoryForm,
  UpdateRepositoryAccessToken,
} from '../modules/pmt/views/RepositoryForm';
import {AddDataEntityForm} from '../modules/pmt/views/assets/DataEntityForm';
import {
  AddFeatureForm,
  UpdateFeatureForm,
} from '../modules/pmt/views/FeatureForm';
import {
  AddScreenForm,
  ScreenCodeEditorForm,
} from '../modules/pmt/views/assets/ScreenForm';
import {ScreenLinkedToForm} from '../modules/pmt/views/assets/UpdateLinkedScreenForm';
import {AddAssetDesignForm} from '../modules/pmt/views/assets/ScreenForm';
import {UpdateModuleForm} from '../modules/pmt/views/ModuleForm';
import {AddRoleForm, UpdateRoleForm} from '../modules/pmt/views/RoleForm';
import {
  AiPromptViewTable,
  MasterPromptViewTable,
} from '../modules/pmt/views/AiPromptViewTable.js';
import {
  AiPromptViewForm,
  UpdateAiPromptForm,
} from '../modules/pmt/views/AiPromptForm';
import {AddDataServiceForm} from '../modules/pmt/views/assets/DataServiceForm.js';
import {UpdateAssigneeForm} from '../modules/task/views/UpdateAssigneeForm';
import {UpdateEstHourForm} from '../modules/task/views/UpdateEstHourForm';
import {FeaturePullRequestTable} from '../modules/pmt/views/FeaturePullRequestTable';
import {ShowIgnoreChats} from '../modules/pmt/views/ShowIgnoreChats';
import {FeatureTasksTable} from '../modules/task/views/FeatureTasksTable';
import {UpdateBugForm} from '../modules/pmt/views/BugForm';
import {AddBugCommentForm} from '../modules/pmt/views/BugCommentForm';
import {CommentScreen} from '../modules/pmt/views/CommentTable';
import {
  DeploymentPlatformAddForm,
  DeploymentPlatformUpdateForm,
} from '../modules/common/views/DeploymentPlatformForm';
import {
  InfrastructureSetup,
  JenkinsSetupForm,
  MapDomain,
  ProjectEnvironmentAddForm,
  ProjectEnvironmentUpdateForm,
  SyncJenkinPipeline,
  UploadEnvFile,
} from '../modules/common/views/ProjectEnvironmentForm.js';
import {
  MasterPromptViewForm,
  UpdateMasterPromptForm,
} from '../modules/pmt/views/MasterPromptForm';
import {
  UpdateMilestoneCompletedDate,
  UpdateMilestoneForm,
} from '../modules/pmt/views/ProjectMilestoneFormView';
import {FeatureMultipleTypesImagePreviewer} from '../app-components/renders/featureTaskDesignPreviewer/FeatureMultipleTypesImagePreviewer';
import {ActiveElementsTable} from '../modules/common/views/ActiveElementsTable';
import {TypesTable} from '../modules/common/views/TypesTable';
import {AccessGroupTable} from '../modules/common/views/AccessGroupList';
import {UserAccessTable} from '../modules/common/views/UserAccessList';
import {AssetDesignsViewer} from '../app-components/renders/featureTaskDesignPreviewer/AssetDesignsViewer';
import {isMobile} from '@unthinkable/react-utils';
import {
  AWSConfigAddForm,
  AWSConfigEditForm,
} from '../modules/common/views/AwsForm';
import {FeaturePullRequestForm} from '../modules/pmt/views/FeaturePullRequestForm.js';
import {
  AddApiConfigurationForm,
  EditApiConfigurationForm,
} from '../modules/pmt/views/ApiConfigurationForm';
import {ActivityLogScreen} from '../modules/pmt/views/ActivityLogTable';
import {AddReview, EditReview} from '../modules/task/views/TaskReviewForm';
import {EditPmtMasterTaskAssignmentForm} from '../modules/task/views/ProjectMasterTaskAssignmentForm';
import {VCSTable} from '../modules/pmt/views/VCSTable';
import {RepositoriesTable} from '../modules/pmt/views/RepositoriesTable';
import {FeatureAssetTasksTable} from '../modules/task/views/FeatureTasksTable';
import {ProjectEmployeeAssignmentTable} from '../modules/common/views/ProjectEmployeeAssignmentTable';
import {SupportedDevicesForm} from '../modules/pmt/views/SupportedDevicesForm';
import {BranchCheckout} from '../modules/pmt/actions/BranchCheckout';
import {ColorTheme} from '../modules/pmt/views/ColorTheme';
import {
  AddButton,
  DownloadButton,
  HeaderButton,
} from '../components/button/AddButton';
import {AddColorForm, UpdateColorForm} from '../modules/pmt/views/ColorForm';
import {FontTheme} from '../modules/pmt/views/FontTheme';
import {AddThemeForm, UpdateThemeForm} from '../modules/pmt/views/ThemeForm';
import {AddFontForm, UpdateFontForm} from '../modules/pmt/views/FontForm';
import {MergeForm} from '../modules/pmt/views/MergeForm';
import {AccessResourceTable} from '../modules/common/views/AccessResourceList';
import {AccessRoleTable} from '../modules/common/views/AccessRoleList';
import {UpdateFeatureStageForm} from '../modules/pmt/views/UpdateFeatureStageForm';
import DeploymentHistoryTable from '../modules/pmt/views/DeploymentHistoryTable';
import {PMTTaskAssignmentTable} from '../modules/task/views/PMTTaskAssignmentTable';
import {
  AddQATestCaseForm,
  CopyTestCaseForm,
  UpdateQATestCaseForm,
} from '../modules/pmt/views/assets/QATestCaseForm';
import {ChangeModuleForm} from '../modules/pmt/views/ChangeModuleForm';
import {FeatureReview} from '../modules/pmt/views/FeatureReview';
import {ProjectJiraCredentialTable} from '../modules/pmt/views/ProjectJiraCredentialTable';
import {
  AddProjectJiraCredentialForm,
  EditProjectJiraCredentialForm,
} from '../modules/pmt/views/ProjectJiraCredentialForm';
import {EditProjectJiraCredentialTokenForm} from '../modules/pmt/views/UpdateJiraCredentialTokenForm';
import {
  AddProjectDescriptionForm,
  UpdateProjectDescriptionForm,
} from '../modules/pmt/views/ProjectDescriptionForm';
import {
  AddModuleDescriptionForm,
  UpdateModuleDescriptionForm,
} from '../modules/pmt/views/ModuleDescriptionForm';
import {AwsSetupForm} from '../modules/common/views/AwsSetupForm';
import {UpdateRepositoryEnvironmentConfigurationForm} from '../modules/pmt/views/RepositoryEnvironmentConfigurationForm';
import {BoilerplateCloneAndCheckoutBranch} from '../modules/pmt/views/BoilerplateCloneAndCheckoutBranch';
import {DeploymentConfigFileForm} from '../modules/pmt/views/DeploymentConfigFileForm';
import TeamTaskMenu from '../modules/task/views/TeamTaskMenu';
import {FeatureCopyLink} from '../modules/pmt/actions/FeatureCopyLink';
import {ProjectOverdueTasksTab} from '../modules/task/views/ProjectOverdueTasksTab.js';
import {MilestoneMenu} from '../modules/pmt/views/MilestoneMenu.js';
import {UpdateMilestoneTargetForm} from '../modules/pmt/views/UpdateMilestoneTargetForm.js';
import {MyTeamEfficiencyTable} from '../modules/task/views/EfficiencyTable';
import {PrFileChangedTable} from '../modules/pmt/views/PrFileChangedTable.js';
import {
  ProjectPlanDetailList,
  ProjectOrderMappingsForAm,
} from '../modules/pmt/views/ProjectOrderMappingsTable.js';
import {
  MapMultipleFeatureForm,
  MapFeatureForm,
} from '../modules/pmt/views/MapFeatureForm.js';
import {UpdateIssueForm} from '../modules/pmt/views/IssueForm.js';
import {
  ImportErrorMessagesTable,
  ImportDataTable,
} from '../modules/pmt/views/assets/ImportDataTable.js';
import {MappedScreenList} from '../modules/pmt/views/assets/MappedScreenList.js';
import {AddThirdPartyIntegrationForm} from '../modules/pmt/views/assets/ThirdPartyIntegrationForm.js';
import {
  APIEndpointCodeEditorForm,
  AddApiEndpointForm,
  ApiExecuteForm,
} from '../modules/pmt/views/assets/ApiEndPointForm.js';
import {TeamTasksTab} from '../modules/task/views/ProjectActiveTasksTab';
import {
  EditBilledProjectForm,
  EditUnbilledProjectForm,
  ProjectDescriptionForm,
  ProjectDetailsForm,
} from '../modules/common/views/ProjectForm.js';
import {VersionScreen} from '../modules/pmt/views/assets/VersionView.js';
import {
  AddRepositoryFolderPathForm,
  UpdateRepositoryFolderPathForm,
} from '../modules/pmt/views/RepositoryFolderPathForm.js';
import {RepositoryFileTable} from '../modules/pmt/views/RepositoryFolderPathScreen.js';
import {CodeEditor} from '../modules/pmt/views/CodeEditor';
import {
  AddFileNameForm,
  UpdateFileNameForm,
} from '../modules/pmt/views/FileNameForm';
import {ApiEndpointScreen} from '../modules/pmt/views/assets/ApiEndPointsScreen.js';
import {DatabaseServiceScreen} from '../modules/pmt/views/assets/DataServiceScreen.js';
import {ChangeModelForm} from '../modules/pmt/views/ChangeModelForm.js';
import {AllFeatureApiEndpointScreen} from '../modules/pmt/views/assets/FeatureApiEndpointsScreen.js';
import {AllDataServicesScreen} from '../modules/pmt/views/assets/FeatureDataServicesScreen.js';
import {UpdateUserStoryForm} from '../modules/pmt/views/UserStoryForm.js';
import {MappedFeaturesTable} from '../modules/pmt/views/MappedFeaturesTable.js';
import RepositoriesMenu from '../modules/pmt/views/RepositoriesMenu.js';
import {
  RepositoryCodeSampleAddForm,
  RepositoryCodeSampleEditForm,
} from '../modules/pmt/views/RepositoryCodeSampleForm.js';
import {ImproveSuggestionForm} from '../modules/pmt/views/ImproveSuggestionForm.js';
import {AddCompanyForm} from '@unthinkable/react-login/src/AddCompanyForm';
import {
  ClientAddProjectForm,
  ClientDetailProjectForm,
  ClientEditProjectForm,
} from '../modules/pmt/views/ClientProjectForm.js';
import {
  AddAllUserForm,
  ClientAddUserForm,
  ClientEditUserForm,
  UpdateAllUsers,
} from '../modules/pmt/views/ClientUserForm.js';
import {ProjectDatabaseNamesTable} from '../modules/pmt/views/ProjectDatabaseNamesTable.js';
import {
  AddProjectDatabaseNameForm,
  EditProjectDatabaseNameForm,
} from '../modules/pmt/views/ProjectDatabaseNameForm.js';
import {ChangeDatabaseNameForm} from '../modules/pmt/views/ChangeDatabaseNameForm.js';
import {ProjectDatabasesTable} from '../modules/pmt/views/ProjectDatabasesTable.js';
import {
  AddProjectDatabaseForm,
  EditProjectDatabaseForm,
} from '../modules/pmt/views/ProjectDatabaseForm.js';
import BranchTable from '../modules/pmt/views/BranchTable.js';
import {DeploymentPlatformsTable} from '../modules/pmt/views/DeploymentPlatformTable.js';
import {ChangeApiForm} from '../modules/pmt/views/ChangeApiForm.js';
import {
  MappedUserstoryScreen,
  SelectUserstoryTable,
} from '../modules/pmt/views/assets/MappedUserstoryScreen.js';
import {Labels} from '../modules/pmt/constants/Label';
import {ModulesArchiveTable} from '../modules/pmt/views/ModulesArchiveTable.js';
import {ArchiveFeaturesTable} from '../modules/pmt/views/FeaturesTable.js';
import {
  AddBoilerplateForm,
  AddLibraryForm,
  ChangeIntegratedProjectLibraryVersion,
  EditBoilerplateForm,
  EditLibraryForm,
  ImportBoilerplateForm,
  ImportLibraryForm,
  PublishLibraryForm,
} from '../modules/pmt/views/assets/LibraryForm';
import {ProjectLibraryVersionTable} from '../modules/pmt/views/assets/ProjectLibraryVersionTable';
import {CloudInfraTable} from '../modules/pmt/views/CloudInfrastructureTable.js';
import {
  CloudInfraAddForm,
  CloudInfraDetail,
} from '../modules/pmt/views/CloudInfrastructureForm.js';
import {MappedModelViewScreen} from '../modules/pmt/views/assets/ProjectModelViews';
import {ModelViewScreen} from '../modules/pmt/views/assets/ModelViewScreen';
import {AddModelViewForm} from '../modules/pmt/views/assets/ModelViewForm';
import {ScreenMappedAPIScreen} from '../modules/pmt/views/assets/ScreenMappedAPIScreen';
import {ChangeFeatureStatusForm} from '../modules/pmt/views/ChangeFeatureStatusForm.js';
import {ImportModuleForm} from '../modules/pmt/views/assets/ImportModuleForm';
import {AiRoleForm} from '../modules/pmt/views/assets/AiRoleForm.js';
import {ScreenIA} from '../modules/pmt/views/assets/ScreenIA';
import {ViewLogs} from '../modules/common/views/ViewLogs.js';
import {SwaggerConfigForm} from '../modules/pmt/views/assets/SwaggerConfigForm.js';

export const pmtStackScreens = [
  {
    name: 'project-members',
    component: ProjectEmployeeAssignmentTable,
    options: {
      header: props => <NavHeader {...props} title={'Members'} />,
    },
  },
  {
    name: 'ai-prompts-table',
    component: AiPromptViewTable,
    options: {
      header: props => <NavHeader {...props} title={'Ai Prompt'} />,
    },
  },
  {
    name: 'active-element',
    component: ActiveElementsTable,
    options: {
      header: props => <NavHeader {...props} title={'Active Elements'} />,
    },
  },
  {
    name: 'data-entity',
    component: TypesTable,
    options: {
      header: props => <NavHeader {...props} title={'Data Entity'} />,
    },
  },
  {
    name: 'access-groups',
    component: AccessGroupTable,
    options: {
      header: props => <NavHeader {...props} title={'Access Groups'} />,
    },
  },
  {
    name: 'user-access',
    component: UserAccessTable,
    options: {
      header: props => <NavHeader {...props} title={`User Access`} />,
    },
  },
  {
    name: 'access-resources',
    component: AccessResourceTable,
    options: {
      header: props => <NavHeader {...props} title={'Access Resources'} />,
    },
  },
  {
    name: 'access-role',
    component: AccessRoleTable,
    options: {
      header: props => <NavHeader {...props} title={`Access Roles`} />,
    },
  },
  {
    name: 'pmt-task-assignment-table',
    component: PMTTaskAssignmentTable,
    options: {
      header: props => (
        <NavHeader
          title={`Asset & auto-task assignment (${
            props.route?.params?.fromModule ? 'Module' : 'Project'
          })`}
          secondaryTitle={props.route?.params?.secondaryTitle}
          {...props}
        />
      ),
    },
  },
  {
    name: 'asset-version',
    component: VersionScreen,
    options: {
      header: props => (
        <NavHeader
          title={'Versions'}
          secondaryTitle={props?.route?.params?.row?.name}
          {...props}
        />
      ),
    },
  },
  {
    name: 'project-team-active-tasks',
    component: TeamTasksTab,
    options: {
      header: props => {
        const {
          route: {params},
        } = props;
        let name = params?.assigned_to?.name;
        let fName = name?.toString().split(' ')[0];
        return (
          <NavHeader
            {...props}
            title={fName ? `${fName}'s tasks` : `Employee's tasks`}
          />
        );
      },
    },
  },
  {
    name: 'project-library-versions',
    component: ProjectLibraryVersionTable,
    options: {
      header: props => {
        const {
          route: {params},
        } = props;

        return (
          <NavHeader
            {...props}
            title={'Library Versions'}
            secondaryTitle={params?.row?.name}
          />
        );
      },
    },
  },
];

export const pmtLeftMenuScreens = [
  {
    name: 'milestone-menu',
    component: MilestoneMenu,
    options: {
      header: props => {
        const {
          route: {params},
        } = props;
        const {milestone} = params;
        return (
          <NavHeader {...props} title={`${milestone?.milestone} (Milestone)`} />
        );
      },
    },
  },
  {
    name: 'project-employee-overdue-detail',
    component: ProjectOverdueTasksTab,
    options: {
      header: props => {
        const {route: {params: {row} = {}} = {}} = props;
        const firstName = row?.name || row?.assigned_to?.name;
        let title = `${firstName.toString().split(' ')[0]}'s Overdue Tasks`;

        return (
          <NavHeader {...props} title={title} secondaryTitle={row?.span} />
        );
      },
    },
  },
  {
    name: 'project-team',
    component: ProjectTeamMenu,
    options: {
      size: 'large',
      header: props => {
        return <NavHeader {...props} title={`Project Team`} />;
      },
    },
  },
  {
    name: 'project-home',
    component: ProjectHome,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`${props?.route?.params?.project?.project} (Project)`}
        />
      ),
    },
  },
  {
    name: 'module-detail',
    component: ModuleDetail,
    options: {
      header: props => (
        <NavHeader
          {...props}
          title={`${props?.route?.params?.module?.module} (Module)`}
        />
      ),
    },
  },
  {
    name: 'feature-detail-screen',
    component: FeatureDetail,
    options: {
      header: props => {
        const {params, route} = props || {};
        const {feature_id, feature} = params || {};
        return (
          <NavHeader
            {...props}
            title={`${route?.params?.feature.feature} (${Labels.Feature})`}
            actions={[
              route?.params?.featureType === 'feature' && (
                <FeatureCopyLink params={params} />
              ),
              <BranchCheckout
                feature_id={feature_id || feature?._id}
                {...props}
              />,
            ]}
          />
        );
      },
    },
  },
  {
    name: 'team-active-tasks',
    component: TeamTaskMenu,
    options: {
      header: props => {
        const {
          route: {params},
        } = props;
        let name = params?.assigned_to?.name;
        let fName = name?.toString().split(' ')[0];
        return (
          <NavHeader
            {...props}
            title={fName ? `${fName}'s tasks` : `Employee's tasks`}
          />
        );
      },
    },
  },
  {
    name: 'repositories-detail',
    component: RepositoriesMenu,
    options: {
      header: props => {
        const {
          route: {params},
        } = props;
        let repository = params?.repository?.repository;
        return <NavHeader {...props} title={`${repository} (Repository)`} />;
      },
    },
  },
];

export const pmtModalScreens = [
  {
    name: `display-${Labels.Features_lower}`,
    component: MappedFeaturesTable,
    options: {
      size: 'medium',
      header: {
        title: Labels.Features,
        secondaryTitle: ({route: {params}}) => {
          const {secondaryTitle} = params;
          return secondaryTitle || '';
        },
      },
    },
  },
  {
    name: 'feature-tasks-list',
    component: FeatureAssetTasksTable,
    options: {size: 'extraLarge'},
  },
  {
    name: 'screens-list',
    component: MappedScreenList,
    options: {size: 'medium'},
  },
  {
    name: 'project-vcs',
    component: VCSTable,
    options: {
      ...(isMobile && {height: '70%'}),
      header: {
        title: 'VCS',
      },
    },
  },
  {
    name: 'project-repositories',
    component: RepositoriesTable,
    options: {size: 'extraLarge'},
  },
  {
    name: 'feature-tasks',
    component: FeatureTasksTable,
    options: {
      size: 'extraLarge',
      header: {
        title: () => 'Tasks',
        secondaryTitle: props => {
          const {
            route: {
              params: {title},
            },
          } = props;
          return title || '';
        },
      },
    },
  },
  {
    name: 'pull-request-table',
    component: FeaturePullRequestTable,
    options: {
      size: 'extraLarge',
    },
  },
  {
    name: 'show-ignore-chats',
    component: ShowIgnoreChats,
    options: {size: 'large'},
  },
  {
    name: 'comment-table',
    component: CommentScreen,
    options: {
      header: {
        title: props => props?.route?.params?.title || 'Comments',
        secondaryTitle: props => {
          let {comment_source, comment_source_id, secondaryTitle} =
            props?.route?.params || {};
          if (!secondaryTitle && comment_source_id?.name) {
            let sourceLabel = comment_source?.label;
            if (sourceLabel) {
              secondaryTitle = sourceLabel + ' : ' + comment_source_id.name;
            } else {
              secondaryTitle = comment_source_id.name;
            }
          }
          return secondaryTitle;
        },
      },
      ...(isMobile ? {position: 'fullScreen'} : {width: '75%', height: '90%'}),
    },
  },
  {
    name: 'master-prompt',
    component: MasterPromptViewTable,
  },
  {
    name: 'employee-team-efficiency',
    component: MyTeamEfficiencyTable,
    options: {
      size: 'max',
      header: {
        title: props => {
          const {
            route: {params: {title} = {}},
          } = props;
          return title;
        },
      },
    },
  },
  {
    name: 'project-data-service-list',
    component: DatabaseServiceScreen,
    options: {
      size: 'max',
      header: {
        title: 'Data Services',
        secondaryTitle: props => {
          const {
            route: {params: {title, dataEntity} = {}},
          } = props;
          return title || 'Model: ' + dataEntity?.model;
        },
      },
    },
  },
  {
    name: 'all-project-data-service-list',
    component: AllDataServicesScreen,
    options: {
      size: 'max',
      header: {
        title: 'Data Services',
        secondaryTitle: props => {
          const {
            route: {params: {dataEntity, title} = {}},
          } = props;
          return title || 'Model: ' + dataEntity?.model;
        },
      },
    },
  },
  {
    name: 'all-project-userstory-list',
    component: MappedUserstoryScreen,
    options: {
      size: 'large',
    },
  },
  {
    name: 'archived-module-screen',
    component: ModulesArchiveTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'archived-feature-screen',
    component: ArchiveFeaturesTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'map-userstories',
    component: SelectUserstoryTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'project-api-list',
    component: ApiEndpointScreen,
    options: {
      size: 'max',
      header: {
        title: 'API Endpoints',
        secondaryTitle: props => {
          const {
            route: {params: {dataEntity} = {}},
          } = props;
          return 'Model: ' + dataEntity?.model;
        },
      },
    },
  },
  {
    name: 'all-project-api-list',
    component: AllFeatureApiEndpointScreen,
    options: {
      size: 'max',
      header: {
        title: 'API Endpoints',
        secondaryTitle: props => {
          const {
            route: {params: {dataEntity} = {}},
          } = props;
          return 'Model: ' + dataEntity?.model;
        },
      },
    },
  },
  {
    name: 'project-model-view-list',
    component: ModelViewScreen,
    options: {
      size: 'max',
      header: {
        title: 'Views',
        secondaryTitle: props => {
          const {
            route: {params: {dataEntity} = {}},
          } = props;
          return 'Model: ' + dataEntity?.model;
        },
        actions: ({route: {params}}) => {
          return [
            <AddButton
              title="View"
              view="add-project-model-view"
              params={params}
            />,
          ];
        },
      },
    },
  },
  {
    name: 'task-image-previewer',
    component: FeatureMultipleTypesImagePreviewer,
    options: {
      position: 'fullScreen',
    },
  },
  {
    name: 'asset-designs-viewer',
    component: AssetDesignsViewer,
    options: {
      position: 'fullScreen',
    },
  },
  {
    name: 'activity-logs',
    component: ActivityLogScreen,
    options: {
      size: 'extraLarge',
      header: {
        title: 'ActivityLogs',
        secondaryTitle: props => {
          const {
            route: {params: {title} = {}},
          } = props;
          return title || '';
        },
      },
    },
  },
  {
    name: 'feature-review',
    component: FeatureReview,
    options: {
      position: 'screenRight',
      width: 600,
      header: {
        title: () => {
          return 'Feature reviews';
        },
      },
      backgroundColor: '#F7F7F7',
    },
  },
  {
    name: 'feature-branch-table',
    component: BranchTable,
    options: {
      size: 'max',
      header: {
        title: 'Repositories',
      },
      width: 1350,
      height: 700,
    },
  },
  {
    name: 'deployment-histories',
    component: DeploymentHistoryTable,
    options: {
      size: 'large',
      header: {
        title: 'Deployment Histories',
        secondaryTitle: ({route: {params}}) =>
          `${params.environment?.environment_id?.label} (${params.repository?.repository})`,
      },
    },
  },
  {
    name: 'project-import-data',
    component: ImportDataTable,
    options: {
      size: 'extraLarge',
      header: {
        title: 'Import Data',
        actions: props => {
          const {route: {params} = {}} = props;
          const {sampleId} = params || {};
          return [
            sampleId && (
              <DownloadButton uri={`/files/${sampleId}`} text={'Sample'} />
            ),
          ];
        },
      },
    },
  },
  {
    name: 'project-import-errors',
    component: ImportErrorMessagesTable,
    options: {
      size: 'large',
      header: {
        title: props => {
          const {
            route: {params},
          } = props;

          const {row} = params || {};

          return `Import error for file : ${row?.file?.filename}`;
        },
        secondaryTitle: props => {
          const {
            route: {params},
          } = props;

          const {row} = params || {};
          const {error_messages = [], success_count = 0} = row || {};

          return `Success: ${success_count} | Failed: ${
            error_messages?.length || 0
          }`;
        },
      },
    },
  },
  {
    name: 'boilerplate-clone-and-checkout-branch',
    component: BoilerplateCloneAndCheckoutBranch,
    options: {
      size: 'small',
      header: {
        title: 'Clone And Checkout Branch',
        secondaryTitle: ({route: {params}}) => {
          return params?.row?.repository_id?.label;
        },
      },
    },
  },
  {
    name: 'project-jira-credential-table',
    component: ProjectJiraCredentialTable,
    options: {size: 'large'},
  },
  {
    name: 'pr-file-changed-render',
    component: PrFileChangedTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'project-order-plan-details',
    component: ProjectPlanDetailList,
    options: {
      size: 'large',
    },
  },
  {
    name: 'project-order-mapping-list-for-am',
    component: ProjectOrderMappingsForAm,
    options: {
      size: 'extraLarge',
    },
  },
  {
    name: 'code-editor',
    component: CodeEditor,
    options: {
      position: 'fullScreen',
      header: {
        title: 'Code Editor',
        secondaryTitle: props => {
          const {
            route: {params},
          } = props;
          return params?.file_name;
        },
      },
    },
  },
  {
    name: 'file-path-table',
    component: RepositoryFileTable,
    options: {
      size: 'medium',
      height: 500,
      header: {
        title: 'Files',
        secondaryTitle: ({route: {params}}) => {
          return params?.folderPath?.name;
        },
      },
    },
  },
  {
    name: 'project-databases',
    component: ProjectDatabasesTable,
    options: {
      size: 'large',
      header: {
        title: 'Databases',
      },
    },
  },
  {
    name: 'project-database-names',
    component: ProjectDatabaseNamesTable,
    options: {
      size: 'large',
      header: {
        title: 'Database Names',
        secondaryTitle: ({route: {params}}) => {
          return params?.database?.database;
        },
      },
    },
  },
  {
    name: 'deployment-platforms',
    component: DeploymentPlatformsTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'screen-model-view',
    component: MappedModelViewScreen,
    options: {
      size: 'large',
    },
  },
  {
    name: 'screen-apis',
    component: ScreenMappedAPIScreen,
    options: {
      size: 'large',
    },
  },
  {
    name: 'cloud-infra-configuration',
    component: CloudInfraTable,
    options: {
      size: 'large',
    },
  },
  {
    name: 'screen-ia',
    component: ScreenIA,
    options: {
      size: 'max',
      closeable: false,
    },
  },
  {
    name: 'deployment-logs',
    component: ViewLogs,
    options: {
      header: {
        title: 'Logs',
      },
      size: 'large',
      height: 400,
    },
  },
];

export const formModalPmtScreens = [
  {
    name: 'edit-review',
    component: EditReview,
  },
  {
    name: 'add-review',
    component: AddReview,
    options: {size: 'medium', minHeight: '30%'},
  },
  {
    name: 'update-feature-status',
    component: ChangeFeatureStatusForm,
    options: {size: 'small'},
  },
  {
    name: 'change-module',
    component: ChangeModuleForm,
    options: {size: 'small'},
  },
  {
    name: 'change-data-entity',
    component: ChangeModelForm,
    options: {size: 'small'},
  },
  {
    name: 'change-api',
    component: ChangeApiForm,
    options: {size: 'small'},
  },
  {name: 'add-asset-design', component: AddAssetDesignForm},
  {
    name: 'role-detail',
    component: UpdateRoleForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-role',
    component: AddRoleForm,
    options: {
      size: 'small',
    },
  },
  {name: 'module-edit', component: UpdateModuleForm},
  {name: 'add-module-description', component: AddModuleDescriptionForm},
  {name: 'module-description-detail', component: UpdateModuleDescriptionForm},
  {
    name: 'merge-detail',
    component: MergeForm,
    options: {
      size: 'small',
    },
  },
  {name: 'project-detail', component: EditBilledProjectForm},
  {name: 'project-description-form', component: ProjectDescriptionForm},
  {name: 'project-detail-non-editable', component: ProjectDetailsForm},
  {name: 'project-unbilled-detail', component: EditUnbilledProjectForm},

  {name: 'add-project-description', component: AddProjectDescriptionForm},
  {name: 'project-description-detail', component: UpdateProjectDescriptionForm},
  {
    name: 'add-feature',
    component: AddFeatureForm,
    options: {
      width: 500,
      minHeight: '30%',
    },
  },
  {
    name: 'feature-detail',
    component: UpdateFeatureForm,
    options: {
      width: 500,
      minHeight: '30%',
    },
  },
  {
    name: 'update-feature-stage',
    component: UpdateFeatureStageForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-view',
    component: AddScreenForm,
    options: {
      size: 'medium',
    },
  },
  {name: 'add-model', component: AddDataEntityForm, options: {size: 'large'}},
  {
    name: 'add-qa-test-case',
    component: AddQATestCaseForm,
    options: {
      size: 'small',
      width: 600,
      height: 600,
    },
  },
  {
    name: 'copy-qa-test-case',
    component: CopyTestCaseForm,
    options: {
      size: 'small',
      width: 600,
      height: 600,
    },
  },
  {name: 'qa-test-case-detail', component: UpdateQATestCaseForm},
  {
    name: 'add-controller',
    component: AddApiEndpointForm,
    options: {size: 'large', height: 600},
  },
  {
    name: 'update-assignee',
    component: UpdateAssigneeForm,
    options: {size: 'small'},
  },
  {
    name: 'milestone-target-date-form',
    component: UpdateMilestoneTargetForm,
    options: {size: 'small'},
  },
  {
    name: 'update-est-hours',
    component: UpdateEstHourForm,
    options: {size: 'small'},
  },
  {
    name: 'map-screen',
    component: ScreenLinkedToForm,
    options: {size: 'small'},
  },
  {name: 'vcs-detail', component: UpdateVCSForm, options: {size: 'small'}},
  {
    name: 'add-vcs',
    component: AddVCSForm,
    options: {size: 'small'},
  },
  {
    name: 'add-repository',
    component: AddRepositoryForm,
    options: {size: 'extraLarge'},
  },
  {
    name: 'add-repository-sec',
    component: AddRepositoryForm,
    options: {size: 'large'},
  },
  {
    name: 'update-repository-environment-configuration',
    component: UpdateRepositoryEnvironmentConfigurationForm,
    options: {size: 'auto', width: 400},
  },
  {
    name: 'update-repository-access-token',
    component: UpdateRepositoryAccessToken,
    options: {size: 'small'},
  },
  {
    name: 'add-api-configuration-form',
    component: AddApiConfigurationForm,
    options: {size: 'large'},
  },
  {
    name: 'edit-api-configuration-form',
    component: EditApiConfigurationForm,
    options: {size: 'large'},
  },
  {
    name: 'add-project-jira-credential-form',
    component: AddProjectJiraCredentialForm,
  },
  {
    name: 'edit-project-jira-credential-form',
    component: EditProjectJiraCredentialForm,
  },
  {
    name: 'edit-project-jira-credential-token',
    component: EditProjectJiraCredentialTokenForm,
    options: {size: 'small'},
  },
  {
    name: 'add-data-service',
    component: AddDataServiceForm,
    options: {
      size: 'small',
      width: 500,
    },
  },
  {
    name: 'add-third-party-integration',
    component: AddThirdPartyIntegrationForm,
    options: {
      size: 'small',
      width: 500,
    },
  },
  {
    name: 'pull-request-form',
    component: FeaturePullRequestForm,
    options: {
      size: 'small',
      width: 600,
    },
  },
  {name: 'bug-detail', component: UpdateBugForm},
  {
    name: 'add-bug-comment',
    component: AddBugCommentForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'supported-devices',
    component: SupportedDevicesForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-company',
    component: AddCompanyForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'deployment-platform-add-form',
    component: DeploymentPlatformAddForm,
    options: {size: 'auto', width: 500},
  },
  {
    name: 'cloud-infra-add-form',
    component: CloudInfraAddForm,
    options: {size: 'medium'},
  },
  {
    name: 'cloud-infra-detail',
    component: CloudInfraDetail,
    options: {size: 'medium'},
  },
  {
    name: 'deployment-platform-detail',
    component: DeploymentPlatformUpdateForm,
    options: {size: 'auto', width: 500},
  },
  {
    name: 'project-environment-add-form',
    component: ProjectEnvironmentAddForm,
    options: {size: 'small'},
  },
  {
    name: 'project-environment-detail',
    component: ProjectEnvironmentUpdateForm,
    options: {size: 'small'},
  },
  {
    name: 'upload-env-file',
    component: UploadEnvFile,
    options: {size: 'small'},
  },
  {
    name: 'domain-map',
    component: MapDomain,
    options: {size: 'small'},
  },
  {
    name: 'sync-jenkins-pipeline',
    component: SyncJenkinPipeline,
    options: {size: 'medium'},
  },
  {
    name: 'infrastructure-setup',
    component: InfrastructureSetup,
    options: {size: 'small'},
  },
  {
    name: 'jenkins-setup-form',
    component: JenkinsSetupForm,
    options: {size: 'small'},
  },
  {name: 'master-prompt-form', component: MasterPromptViewForm},
  {name: 'update-master-prompt', component: UpdateMasterPromptForm},
  {name: 'update-milestone-detail', component: UpdateMilestoneForm},
  {
    name: 'update-milestone-completed-date',
    component: UpdateMilestoneCompletedDate,
    options: {size: 'small'},
  },
  {
    name: 'aws-config-add',
    component: AWSConfigAddForm,
    options: {size: 'medium'},
  },
  {
    name: 'aws-config-edit',
    component: AWSConfigEditForm,
    options: {size: 'medium'},
  },
  {
    name: 'aws-setup-form',
    component: AwsSetupForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-pmt-master-task-assignment',
    component: EditPmtMasterTaskAssignmentForm,
  },
  {
    name: 'add-color',
    component: AddColorForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-color',
    component: UpdateColorForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-font',
    component: AddFontForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'edit-font',
    component: UpdateFontForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'add-theme',
    component: AddThemeForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-theme',
    component: UpdateThemeForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'deployment-config-file-form',
    component: DeploymentConfigFileForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'userstory-detail',
    component: UpdateUserStoryForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'update-issue-form',
    component: UpdateIssueForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'ai-role-detail',
    component: AiRoleForm,
    options: {
      size: 'large',
    },
  },
  {
    name: `map-${Labels.Feature_lower}`,
    component: MapFeatureForm,
    options: {size: 'small'},
  },
  {
    name: `map-multiple-${Labels.Features_lower}`,
    component: MapMultipleFeatureForm,
    options: {size: 'small'},
  },
  {
    name: 'add-repository-folder-path',
    component: AddRepositoryFolderPathForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-file-name',
    component: AddFileNameForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-file-name',
    component: UpdateFileNameForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'update-repository-folder-path',
    component: UpdateRepositoryFolderPathForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-code-architecture-form',
    component: RepositoryCodeSampleAddForm,
    options: {
      size: 'extraLarge',
      height: '90%',
    },
  },
  {
    name: 'edit-code-architecture-form',
    component: RepositoryCodeSampleEditForm,
    options: {
      size: 'extraLarge',
      height: '90%',
    },
  },
  {
    name: 'ai-suggestion-form',
    component: ImproveSuggestionForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'ai-prompt',
    component: AiPromptViewForm,
    options: {
      size: 'extraLarge',
    },
  },
  {
    name: 'update-ai-prompt',
    component: UpdateAiPromptForm,
    options: {
      size: 'extraLarge',
    },
  },
  {
    name: 'client-add-project',
    component: ClientAddProjectForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'client-edit-project',
    component: ClientEditProjectForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'client-project-detail',
    component: ClientDetailProjectForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'client-add-user',
    component: ClientAddUserForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'add-all-user',
    component: AddAllUserForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'update-all-user',
    component: UpdateAllUsers,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'client-edit-user',
    component: ClientEditUserForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'api-endpoint-code-editor',
    component: APIEndpointCodeEditorForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'screen-code-editor',
    component: ScreenCodeEditorForm,
    options: {
      size: 'large',
    },
  },
  {
    name: 'add-database-name',
    component: AddProjectDatabaseNameForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'edit-database-name',
    component: EditProjectDatabaseNameForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'change-database-name',
    component: ChangeDatabaseNameForm,
    options: {size: 'small'},
  },
  {
    name: 'add-database',
    component: AddProjectDatabaseForm,
    options: {size: 'small'},
  },
  {
    name: 'edit-database',
    component: EditProjectDatabaseForm,
    options: {size: 'small'},
  },
  {
    name: 'add-library',
    component: AddLibraryForm,
    options: {size: 'medium'},
  },
  {
    name: 'add-boilerplate',
    component: AddBoilerplateForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-boilerplate',
    component: EditBoilerplateForm,
    options: {size: 'medium'},
  },
  {
    name: 'import-boilerplate',
    component: ImportBoilerplateForm,
    options: {size: 'medium'},
  },
  {
    name: 'edit-library',
    component: EditLibraryForm,
    options: {size: 'medium'},
  },
  {
    name: 'publish-library',
    component: PublishLibraryForm,
    options: {size: 'small'},
  },
  {
    name: 'import-libraries',
    component: ImportLibraryForm,
    options: {size: 'small'},
  },
  {
    name: 'change-integrated-project-library-version',
    component: ChangeIntegratedProjectLibraryVersion,
    options: {size: 'small'},
  },
  {
    name: 'add-project-model-view',
    component: AddModelViewForm,
    options: {
      size: 'medium',
    },
  },
  {
    name: 'import-module',
    component: ImportModuleForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'swagger-config',
    component: SwaggerConfigForm,
    options: {
      size: 'small',
    },
  },
  {
    name: 'api-execute-form',
    component: ApiExecuteForm,
    options: {
      size: 'max',
    },
  },
];
