import { getCurrentMonth, useFilter } from '@unthinkable/react-filters';
import { useTheme } from '@unthinkable/react-theme';
import React from 'react';
import { IconButton } from '../../../components/button/IconButton';
import { GroupFilter } from '../../../components/filter/Filters';
import { TabView } from '../../../components/tab';
import { Table } from '../../../components/table/Table';
import { useAppStateContext } from '../../../providers/AppState';
import { randomColor } from '../../../utils/util';


const SalaryTdsTable = props => {
    const { filterParams, searchValue, params } = props;
    return (
        <Table
            {...props}
            api={'/salaryTdsReport'}
            addOnFilter={filterParams}
            params={params}
            eventSourceId="GST"
            searchFields={['payment_no']}
            search={searchValue}
            limit={50}
            columns={[
                {
                    type: 'text',
                    field: 'employee.name',
                    header: 'Employee',
                    minWidth: 150,
                },
                {
                    field: 'employee.employee_code',
                    header: 'Employee Code',
                    type: 'colorTag',
                    colorField: randomColor,
                    width: 160,
                },
                {
                    type: 'text',
                    header: "PAN Number",
                    field: "employee.pan_number",
                    width: 140,
                },
                {
                    field: 'payable_amount',
                    type: 'currency',
                    header: 'Payable Amount',
                    width: 140
                },
                {
                    field: 'tds_sum',
                    type: 'currency',
                    header: 'TDS Amount',
                    width: 140,
                }
            ]}
        />
    );
};

export const SalaryTdsTab = props => {
    let { route: { params } = {} } = props;
    const { Download } = useTheme('icons');
    const { download } = useAppStateContext();
    const { onChangeFilter, searchValue, filterValues, applyFilter } = useFilter({
        params: {
            period: getCurrentMonth(),
        },
    });
    let { filter } = filterValues;
    const tabs = {
        tds: {
            label: 'Salary TDS',
            view: (
                <SalaryTdsTable filterParams={filter} searchValue={searchValue} {...props} />
            ),
            eventSourceId: 'TDS',
            api: '/salaryTdsReport',
            addOnFilter: filter,
            search: searchValue,
            searchFields: ['payment_no'],
            actions: [
                <IconButton
                    icon={Download}
                    onPress={() => {
                        download({
                            uri: '/downloadSalaryTdsReport',
                            props: {
                                addOnFilter: { ...filter },
                            },
                        });
                    }}
                />,
            ],
        },
    };

    return (
        <TabView
            {...props}
            tabs={tabs}
            params={params}
            extraFilter={filter}
            actions={[
                <GroupFilter
                    filterValues={filterValues}
                    applyFilter={applyFilter}
                    filters={[
                        {
                            type: 'date',
                            label: 'Date',
                            field: 'period',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Employee',
                            api: '/employeeSuggestions',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'employee',
                            searchFields: ['name', 'official_email_id', 'employee_code'],
                        },
                        {
                            type: 'multiAutoComplete',
                            label: 'Organization',
                            api: '/organizations',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'organization',
                        },
                        {
                            type: 'autoComplete',
                            label: 'Product',
                            api: '/products',
                            placeholder: 'Select',
                            suggestionField: 'name',
                            valueField: 'name',
                            field: 'product',
                        },
                    ]}
                />,
            ]}
        />
    );
};
