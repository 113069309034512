import React from 'react';
import {Table} from '../../../components/table/Table';
import {Durations} from './components/Durations';
import CountCellRender from '../../../app-components/renders/CountCellRender';
import {useInvoke} from '../../../controllers/useInvoke';
import {randomColor} from '../../../utils/util';

const navigateToOrderResourceAssignment = ({navigation, params}) => ({
  title: 'Assign Resource',
  onPress: props => {
    const {row} = props;
    let {
      order = {},
      skill_required = {},
      hours: employee_per_day_working_hour,
      skill_requirement,
      tool,
      from_date,
      to_date,
    } = row || {};
    let order_allocations = {
      skill_required,
      order,
      skill_requirement,
      recurring_till: order?.recurring_till,
      order_date: order?.order_date,
      employee_per_day_working_hour,
      product: order?.product,
      tool,
      order_date: from_date,
      recurring_till: to_date,
      project: order?.project?.[0],
      new_project_type: project?.new_project_type,
    };
    navigation.navigate(
      order?.order_type == 'r'
        ? 'order-resource-assignment'
        : 'create-fixed-order-assignment',
      {
        order_allocations,
        order_resource_request: row?._id,
      },
    );
  },
});

export const deleteOrderResourceRequest = ({invoke}) => ({
  title: 'Reject Request',
  confirm: {
    title: 'Reject Request',
    message: 'Do you want to reject this request?',
  },
  onPress: props => {
    const {row} = props;
    invoke({
      uri: `/orderresourcerequests/${row?._id}`,
    });
  },
});

const OrderResourceRequestTable = props => {
  let {route: {params = {}} = {}, navigation} = props || {};
  let {data = {}} = params;
  let {skill_required, order, fromOrderWiseAllocations} = data;
  let filter = {};
  if (fromOrderWiseAllocations) {
    filter = {
      skill_required,
      order,
    };
  } else {
    filter = {
      skill_required,
      order,
      status: 'pending',
    };
  }
  const invoke = useInvoke({
    method: 'delete',
    eventSourceId: 'orderresourcerequests',
  });

  return (
    <Table
      api={'/orderwise/orderresourcerequests'}
      params={params}
      eventSourceId={[
        'skillRequirement',
        'employeeAssigned',
        'orderresourcerequests',
      ]}
      addOnFilter={filter}
      columns={[
        {
          header: 'Req No.',
          field: 'skill_requirement.skill_requirement_no',
          type: 'text',
        },
        // {header: 'Order', field: 'order.order_number', type: 'text'},
        {
          header: 'Skill ',
          field: 'tool.name',
          type: 'colorTag',
          colorField: randomColor,
        },
        {
          header: 'Duration',
          align: 'center',
          render: ({row}) => {
            return Durations({
              toDate: row?.to_date,
              fromDate: row?.from_date,
            });
          },
        },
        {
          header: ' Hrs/Day',
          field: 'hours',
          type: 'number',
        },
        {header: 'Notes ', field: 'notes', type: 'text'},
        fromOrderWiseAllocations && {
          render: CountCellRender,
          count_field: 'assign_allocation_count',
          header: 'Allocations',
          align: 'right',
          onPress: ({row}) => {
            navigation.navigate('project-allocations-tab', {
              addOnFilter: {
                order: row?.order?._id,
                skill_requirement: row?.skill_requirement,
              },
            });
          },
        },
      ]}
      moreActions={() => [
        navigateToOrderResourceAssignment({navigation, params}),
        deleteOrderResourceRequest({invoke, params}),
        {
          title: 'Project Resource Working',
          onPress: ({row}) => {
            navigation.navigate(`skillwise-deliveries`, {
              extraFilter: {recur_id: row?.skill_requirement?._id},
            });
          },
        },
      ]}
    />
  );
};

export default OrderResourceRequestTable;
