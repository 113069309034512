import React from 'react';
import {Form} from '../../../components/form/Form';
import {useFormSubmit} from '../../../controllers/useSubmitForm';
import {useAppStateContext} from '../../../providers/AppState';

export const BudgetRequest = props => {
  const {route: {params} = {}} = props;

  const {row, readOnly = false} = params;
  let {onSubmit} = useFormSubmit({
    uri: '/budgetrequests',
    eventSourceId: 'budget',
  });
  const {user, fetch} = useAppStateContext();
  return (
    <Form
      api={`/budget/${row?._id}`}
      onSubmit={onSubmit}
      defaultValues={{
        status: 'Requested',
        request_date: new Date(),
        requested_by: user.employee?._id,
      }}
      readOnly={readOnly}
      computations={{
        'set organization,product and currency': {
          compute: async value => {
            const {data: vendorData} = await fetch({
              uri: '/vendors',
              props: {
                filter: {
                  _id: value.vendor._id,
                },
                fields: {
                  organization: {_id: 1, name: 1},
                  product: {_id: 1, name: 1},
                  department: {_id: 1, name: 1},
                  currency_id: {_id: 1, currency: 1},
                },
                only: true,
              },
            });
            return {
              organization: vendorData?.organization,
              product: vendorData?.product,
              currency: vendorData?.currency_id,
              department: vendorData?.department,
            };
          },
          multi: true,
          dependencies: ['vendor'],
        },
      }}
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Budget details',
          fields: [
            {
              label: 'Vendor',
              type: 'autoComplete',
              field: 'vendor',
              api: `/vendors`,
              // required: true,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
            },
            {
              label: 'Budget Category',
              field: 'budget_category',
              type: 'autoComplete',
              api: '/budgetcategories',
              suggestionField: 'name',
              valueField: 'name',
              required: true,
              size: 6,
            },
            {
              label: 'Description',
              type: 'text',
              field: 'description',
              size: 6,
              required: true,
            },
            {
              label: 'Amount',
              type: 'number',
              field: 'amount',
              size: 3,
            },
            {
              label: 'Currency',
              type: 'autoComplete',
              field: 'currency',
              api: `/currencies`,
              suggestionField: 'currency',
              valueField: 'currency',
              size: 3,
              required: true,
            },
            {
              label: 'Start date',
              field: 'from_date',
              type: 'date',
              size: 3,
            },
            {
              label: 'End date',
              field: 'to_date',
              type: 'date',
              size: 3,
            },
            {
              label: 'Term',
              type: 'autoComplete',
              options: ['Monthly', 'Quaterly', 'Half yearly', 'Yearly'],
              field: 'term',
              size: 6,
              required: true,
            },
            {
              label: 'Is One time',
              type: 'checkbox',
              field: 'is_one_time',
              size: 6,
            },
          ],
        },
        {
          label: 'Team',
          fields: [
            {
              label: 'Team',
              type: 'autoComplete',
              field: 'team',
              api: '/employeeteams',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              filter: {
                requester: {$in: [user.employee._id]},
              },
            },
            {
              label: 'Approver',
              field: 'approver',
              type: 'autoComplete',
              api: `/employeeTeamsuggestion`,
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
              params: ({values}) => {
                return {
                  _id: values?.team?._id,
                };
              },
            },
            {
              label: 'Department',
              field: 'department',
              type: 'autoComplete',
              api: '/departments',
              suggestionField: 'name',
              valueField: 'name',
              size: 6,
              required: true,
            },
          ],
        },
        // {
        //   label: 'Accounting',
        //   fields: [
        //     {
        //       label: 'Organization',
        //       type: 'autoComplete',
        //       field: 'organization',
        //       api: `/organizations`,
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       size: 6,
        //       required: true,
        //     },
        //     {
        //       label: 'Product',
        //       type: 'autoComplete',
        //       field: 'product',
        //       api: '/products',
        //       suggestionField: 'name',
        //       valueField: 'name',
        //       size: 6,
        //       required: true,
        //     },
        //   ],
        // },
      ]}
      {...props}
    />
  );
};

export const AddBudgetRequest = props => {
  return <BudgetRequest header="Add Budget Request" {...props} />;
};

export const EditBudgetRequest = props => {
  return (
    <BudgetRequest mode="edit" header={'Update Budget Request'} {...props} />
  );
};

export const RejectBudgetReason = props => {
  const {route: {params} = {}} = props;

  const {row} = params;
  let {onSubmit} = useFormSubmit({
    uri: `/budgetrequests`,
    eventSourceId: 'budget',
  });

  return (
    <Form
      api={`/budgetrequests/${row._id}`}
      onSubmit={onSubmit}
      beforeSubmit={({data}) => {
        return {
          data: {
            ...data,
            status: 'Rejected',
            rejected_date: new Date(),
          },
        };
      }}
      mode="edit"
      header="Reject reason"
      submitAction="Save"
      eventSourceId="budget"
      layoutFields={[
        {
          label: 'Reject reason',
          field: 'reject_reason',
          type: 'text',
          required: true,
        },
      ]}
      {...props}
    />
  );
};
