import React from 'react';
import { Table } from '../../../components/table/Table';
import { useNavigation } from '@react-navigation/native';
import { TableHeader } from '../../../components/table/Headers';
import { AddButton } from '../../../components/button/AddButton';
import { useInvoke } from '../../../controllers/useInvoke';

export const BlogsTable = () => {
  const navigation = useNavigation();

  const deleteInvoke = useInvoke({
    method: 'delete',
    eventSourceId: 'blog',
  });

  return (
    <Table
      eventSourceId={["blog"]}
      api={`/blogmodels`}
      fields={{
        domain: {
            title: 1,
        },
        title: 1,
        description: 1,
        createdOn: 1,
      }}
      sort={{index: 1}}
      renderHeader={() => (
        <TableHeader
          title="Blogs"
          actions={[
            <AddButton
              title="Add Blog"
              view="add-blog-unthinkable"
            />,
          ]}
        />
      )}
      onRowPress={({ row }) => {
        navigation.navigate('edit-blog-unthinkable', { row });
      }}
      columns={[
        {
          type: 'text',
          field: 'domain.title',
          header: 'Domain',
          width: 120,
        },
        {
          type: 'textArea',
          field: 'title',
          header: 'Title',
        },
        {
          type: 'textArea',
          field: 'description',
          header: 'Description',
        },
        {
          header: 'Created On',
          field: 'createdOn',
          align: 'center',
          type: 'date',
            formatOptions: {
                format: 'DD MMM YY',
            },
          width: 120,
        }
      ]}
      moreActions={() => [
        {
          title: 'Edit',
          onPress: ({ row }) => {
            navigation.navigate('edit-blog-unthinkable', { row });
          },
        },
        {
          title: 'Delete',
          confirm: {
            title: 'Delete',
            message: 'Are you sure you want to Delete?',
            confirmText: 'Delete',
          },
          onPress: ({ row }) => {
            deleteInvoke({
              uri: `/blogmodels/${row?._id}`,
              props: {},
            });
          },
        },
      ]}
    />
  );
};
